
import Types from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case Types.START_GAME:
      return {
        ...state,
        startTime: action.startTime,
        score: 0,
        isTimerRunning: false,
        number: 0,
      };
    case Types.END_GAME:
      return {
        ...state,
        score: 0,
        isTimerRunning: false,
        number: 0,
        question: {},
      };
    case Types.START_TIMER:
      return {
        ...state,
        isTimerRunning: true,
      };
    case Types.STOP_TIMER:
      return {
        ...state,
        isTimerRunning: false,
      };
    case Types.TICK:
      return {
        ...state,
        timeLeft: action.timeLeft,
      };
    default:
      return state;
  }
}
