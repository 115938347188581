import io from "socket.io-client";
import chatActions from "./chatActions";
import userActions from "./userActions";
import gameActions from "./gameActions";
import pictionaryActions from "./pictionaryActions";

const methods = (() => {
  console.log("Creating API Methods");
  const socket = io(APP_CONFIG.SOCKETIO_URL, {
    secure: true,
    path: "/v1",
    reconnection: true,
    reconnectionAttempts: 20,
    reconnectionDelay: 2000,
  });
  window.adminLogin = (password) => {
    socket.emit("adminLogin", password);
  };
  socket.on("connect", () => {
    console.log("connect");
  });
  socket.on("reconnect", () => {
    console.log("reconnect");
  });
  socket.on("disconnect", () => {
    console.log('disconnect'); // undefined
    //refresh;
    /*eslint-disable*/
    window.location.href = window.location.href;
    /*eslint-enable*/
  });
  socket.on("connect_error", (error) => {
    console.log("socketio Connection error: " + error);
  });
  socket.io.engine.on("upgrade", function (transport) {
    console.log("transport changed", transport);
  });
  return {
    ...gameActions(socket),
    ...userActions(socket),
    ...chatActions(socket),
    ...pictionaryActions(socket),
  };
})();

export default methods;
