// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".input-button__input-button__1E-oL {\n  position: relative;\n  display: flex;\n  flex-direction: row; }\n  .input-button__input-button__1E-oL .input-button__input__2n4My {\n    flex: 1 1 auto; }\n    .input-button__input-button__1E-oL .input-button__input__2n4My input {\n      color: #f3f3f3;\n      font-size: 1.3rem; }\n      @media (max-width: 895px) {\n        .input-button__input-button__1E-oL .input-button__input__2n4My input {\n          font-size: 16px; } }\n      .input-button__input-button__1E-oL .input-button__input__2n4My input::placeholder {\n        color: #909090; }\n  .input-button__input-button__1E-oL .input-button__button__3jCCl {\n    border-left: 2px solid #f3f3f3;\n    flex: 0 0 auto;\n    background: #202020;\n    font-size: 1.3rem; }\n    .input-button__input-button__1E-oL .input-button__button__3jCCl button {\n      background: #202020;\n      color: #f3f3f3; }\n      @media (max-width: 895px) {\n        .input-button__input-button__1E-oL .input-button__button__3jCCl button {\n          font-size: 16px; } }\n", ""]);
// Exports
exports.locals = {
	"input-button": "input-button__input-button__1E-oL",
	"input": "input-button__input__2n4My",
	"button": "input-button__button__3jCCl"
};
module.exports = exports;
