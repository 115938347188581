import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import SvgIcon from 'Components/utility/SvgIcon';

import styles from 'Styles/ui/button.scss';


class Button extends React.Component {
  render() {
    const { children, onClick, theme, type } = this.props;
    return (
      <button onClick={onClick} styleName={`button ${theme}`} className="clickable" type={type}>
        <div className="noselect">
          {children}
        </div>
      </button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'light']),
};
Button.defaultProps = {
  onClick: () => {},
  theme: 'default',
  type: 'default',
};

export default cssModules(Button, styles, { allowMultiple: true });
