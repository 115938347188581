import React from "react";
import cssModules from "react-css-modules";
// import ifEmoji from 'if-emoji';
import Scales from "Components/utility/Scales";
import styles from "Styles/pages/chat.scss";

const emotify = (message = "") => {
  const r = new RegExp(/(\[emote:[^\]]*\])/, "gm");
  const rGroup = new RegExp(/\[emote:([^\]]*)\]/, "gm");
  if (r.test(message)) {
    const sections = message.split(r);
    const ret = sections.map((s, i) => {
      if (rGroup.test(s)) {
        const url = s.replace(rGroup, "$1");
        return (
          <span className={styles["message-emote"]} key={i}>
            <img src={url} />
          </span>
        );
      }
      return <span key={i}>{s}</span>;
    });
    return ret;
  }
  return <span>{message}</span>;
};

const ChatMessage = ({
  readableTime,
  name,
  text,
  isFollower = false,
  sentByAdmin = false,
  subtext,
  myName,
  correctGuess = false,
}) => {
  let textEl = text;
  // console.log(text);
  // if (text.length < 4) {
  //   console.log(text.split('').filter(ifEmoji));
  //   if (text.split('').filter(ifEmoji) === text.length) {
  //     textEl = (
  //       <div styleName="emoji">{text}</div>
  //     );
  //   }
  // }
  let iAmTagged = false;
  const tag = "@" + myName;
  const lowered = textEl.toLowerCase();
  const loweredTag = tag.toLowerCase();
  if (lowered.startsWith(`${loweredTag} `)) {
    iAmTagged = true;

    textEl = textEl.slice(tag.length);
  } else if (lowered === loweredTag) {
    iAmTagged = true;
    textEl = "";
  }
  return (
    <div
      styleName={`chat-message-wrap ${sentByAdmin ? "admin" : ""} ${
        isFollower ? "follower" : ""
      }`}
    >
      {!isFollower && (
        <div styleName="message-data">
          <div styleName="name">
            {name}: {sentByAdmin && <span>💻</span>}
          </div>
          <div styleName="time">{readableTime}</div>
          <div styleName="divider" />
        </div>
      )}
      <div styleName={`${correctGuess ? "correct" : ""} message`}>
        {/* <Scales.S> */}
        <div styleName="message-text">
          {iAmTagged && (
            <span>
              <div styleName="message-tag">{myName}</div>
            </span>
          )}
          {emotify(textEl)} {correctGuess && <span>✅</span>}
          {subtext && <span styleName="message-subtext">{subtext}</span>}
        </div>
        {/* </Scales.S> */}
      </div>
    </div>
  );
};

export default cssModules(ChatMessage, styles, { allowMultiple: true });
