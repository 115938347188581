import uuidv1 from 'uuid/v1';

const UserService = (() => {
  const STATES = {
    INITIAL: 'INITIAL',
    REGISTERED: 'REGISTERED',
    NAMED: 'NAMED',
    SPECTATING: 'SPECTATING',
    IN_GAME: 'IN_GAME',
  };
  const state = STATES.INITIAL;
  const generateId = () => uuidv1();
  const getId = (userObj) => {
    let { id } = userObj;
    if (!id) {
      id = generateId();
    }
    return id;
  };
  return {
    generateId,
    getId,
    STATES,
  };
})();

export default UserService;
