import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import emitter from "Util/emitter";
import { createDevLog } from "Util/devlog";

import api from "Actions/api";
import { getChatCache, addToChatCache } from "Actions/chatActions";

import ChatView from "./ChatView";

const log = createDevLog("chat");

const emoteObj = {
  "4Head": "https://static-cdn.jtvnw.net/emoticons/v1/354/2.0",
  "5Head": "https://cdn.frankerfacez.com/emote/239504/1",
  AYAYA: "https://cdn.frankerfacez.com/emoticon/162146/1",
  BOOBA: "https://cdn.betterttv.net/emote/5fa99424eca18f6455c2bca5/1x",
  "D:": "https://cdn.betterttv.net/emote/55028cd2135896936880fdd7/1x",
  EZ: "https://cdn.betterttv.net/emote/5590b223b344e2c42a9e28e3/1x",
  FeelsBadMan: "https://cdn.betterttv.net/emote/566c9fc265dbbdab32ec053b/1x",
  FeelsOkayMan: "https://cdn.frankerfacez.com/emoticon/145947/1",
  FeelsStrongMan: "https://cdn.frankerfacez.com/emote/64210/1",
  HandsUp: "https://cdn.frankerfacez.com/emoticon/229760/1",
  KKonaW: "https://cdn.betterttv.net/frankerfacez_emote/164480/1",
  LUL: "https://static-cdn.jtvnw.net/emoticons/v1/425618/2.0",
  LULW: "https://cdn.frankerfacez.com/emoticon/139407/1",
  NOPERS: "https://cdn.betterttv.net/emote/5ec39a9db289582eef76f733/1x",
  OMEGALUL: "https://cdn.frankerfacez.com/emoticon/128054/1",
  POGGERS: "https://cdn.frankerfacez.com/emoticon/214129/1",
  PepeHands: "https://cdn.frankerfacez.com/emoticon/231552/1",
  PepeLaugh: "https://cdn.frankerfacez.com/emoticon/64785/1",
  Pepega: "https://cdn.frankerfacez.com/emoticon/243789/1",
  Pog: "https://cdn.frankerfacez.com/emoticon/210748/1",
  PogU: "https://cdn.frankerfacez.com/emoticon/256055/1",
  Sadge: "https://cdn.betterttv.net/emote/5e0fa9d40550d42106b8a489/1x",
  WeirdChamp: "https://cdn.frankerfacez.com/emoticon/262468/1",
  YEP: "https://cdn.frankerfacez.com/emote/418189/1",
  catJAM: "https://cdn.betterttv.net/emote/5f1b0186cf6d2144653d2970/1x",
  forsenCD: "https://cdn.frankerfacez.com/emote/249060/1",
  monkaS: "https://cdn.betterttv.net/emote/56e9f494fff3cc5c35e5287e/1x",
  pepeJAM: "https://cdn.betterttv.net/emote/5b77ac3af7bddc567b1d5fb2/1x",
  widepeepoHappy: "https://cdn.frankerfacez.com/emote/270930/1",
};

const checkEmote = (s = "") => {
  return s
    .split(" ")
    .map((word) => {
      if (word in emoteObj) {
        return `[emote:${emoteObj[word]}]`;
      }
      return word;
    })
    .join(" ");
};

const parseMessage = (message = "") => {
  if (message[0] === APP_CONFIG.CHAT_COMMAND_PREFIX) {
    const command = message.substr(1).split(" ");
    if (command[0] === "shrug") {
      return `¯\\_(ツ)_/¯ ${command.slice(1).join(" ")}`;
    }
  }
  return message.slice(0, 800);
};

class Chat extends React.Component {
  static defaultProps = {
    overrideSendFn: null,
  };
  constructor(props) {
    super(props);
    this.handleSend = this.handleSend.bind(this);
    this.handleNewMessage = this.handleNewMessage.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.lastMessageSentAt = 0;
    this.badMarks = 0;
    this.messages = [];
    this.chatWrapperEl = document.getElementById("chat-messages-wrap");
  }
  componentDidMount() {
    this.chatWrapperEl = document.getElementById("chat-messages-wrap");
    this.props.getChatCache(0, true);
    api.subscribeToChat(this.handleNewMessage, this.handleAlert);
    emitter.on("alert", this.handleAlert);
  }
  componentWillUnmount() {
    emitter.off("alert", this.handleAlert);
    api.unsubscribeChat();
  }
  handleNewMessage(newMessage) {
    this.messages.push({
      offsetTime: 0,
      ...newMessage,
      text: checkEmote(newMessage.text),
      isFollower: this.checkIfFollower(newMessage.id),
      readableTime: moment(newMessage.time).format("h:mm a"),
    });
    this.setState({}, () => {
      this.chatWrapperEl.scrollTo(0, this.chatWrapperEl.scrollHeight);
    });
  }
  handleAlert(message) {
    this.messages.push({
      offsetTime: 0,

      ...message,
      time: new Date().getTime(),
    });
    this.setState({}, () => {
      this.chatWrapperEl.scrollTo(0, this.chatWrapperEl.scrollHeight);
    });
  }
  getValue() {
    return document.getElementById("chat-message-input").value;
  }
  clearValue() {
    document.getElementById("chat-message-input").value = "";
  }
  handleSend(e) {
    const { id, name, chatCache, isSilent } = this.props;

    e.preventDefault();
    const message = this.getValue();
    if (message && message.replace(/\s/g, "")) {
      if (this.props.overrideSendFn) {
        console.log("overrode send");
        this.props.overrideSendFn(message);
        this.clearValue();
        return;
      }
      if (message.length > 500) {
        return;
      }
      let isSpam = false;
      const parsedMessage = parseMessage(message);
      const lastMessage = chatCache[0];
      const sendTime = new Date().getTime();
      if (isSilent) {
        isSpam = true;
        this.badMarks = 10;
      } else if (this.badMarks > 10) {
        isSpam = true;
        this.badMarks = Math.max(this.badMarks - 0.1, 0);
      } else if (lastMessage === parsedMessage) {
        log("spam?");
        if (parsedMessage.length > 50) {
          log("too long, spam");
          isSpam = true;
          this.badMarks += 2;
        } else if (sendTime - this.lastMessageSentAt < 2000) {
          log("duplicate too quick, spam");
          isSpam = true;
          this.badMarks += 1;
        }
      } else if (sendTime - this.lastMessageSentAt < 400) {
        log("too quick, spam");
        isSpam = true;
        this.badMarks += 1;
      } else if (chatCache.filter((s) => s === parsedMessage).length >= 3) {
        log("too many duplicates");
        isSpam = true;
        this.badMarks += 2;
      }
      if (isSpam && this.badMarks <= 2) {
        isSpam = false;
        log("user is clean, sending, anyway");
      }

      this.lastMessageSentAt = sendTime;
      log(this.badMarks);
      if (isSpam) {
        this.handleNewMessage({
          name,
          text: parsedMessage,
          time: sendTime,
          id,
        });
      } else {
        this.badMarks = Math.max(this.badMarks - 0.5, 0);
        api.sendChatMessage(parsedMessage, name, id);
      }
      this.props.addToChatCache(parsedMessage);
      this.clearValue();
      if (this.badMarks === 3) {
        this.handleAlert({
          message: "👀",
          subtext: "Please don't spam the chat",
          type: "info",
          time: sendTime,
          id,
        });
      } else if (this.badMarks === 3.5) {
        this.handleAlert({
          message: "👀",
          subtext: "Please don't spam the chat",
          type: "info",
          time: sendTime,
          id,
        });
      }
    }
  }
  handleKeyUp(e, el) {
    const { chatCache, chatCacheCursor } = this.props;
    if (e.keyCode === 38 && el) {
      this.props.getChatCache(1);
      el.value = chatCache[chatCacheCursor];
    } else if (e.keyCode === 40 && el) {
      this.props.getChatCache(-1);
      el.value = chatCache[chatCacheCursor];
    }
  }
  checkIfFollower(id) {
    const previousMessageExists = this.messages.length;
    return !!(
      previousMessageExists && this.messages[this.messages.length - 1].id === id
    );
  }
  render() {
    return (
      <ChatView
        messages={this.messages}
        onSend={this.handleSend}
        onChatKeyUp={this.handleKeyUp}
        displayMessages={this.props.displayMessages}
        isLanding={this.props.isLanding}
        isTimerRunning={this.props.isTimerRunning}
        myName={this.props.name}
      />
    );
  }
}

const mapStateToProps = ({ user, chat, game }) => ({
  id: user.id,
  isSilent: user.silent,
  name: user.name,
  chatCache: chat.chatCache,
  chatCacheCursor: chat.cursor,
  isTimerRunning: game.isTimerRunning,
});
const mapDispatchToProps = {
  addToChatCache,
  getChatCache,
};
export default connect(mapStateToProps, mapDispatchToProps)(Chat);
