import Types from './types';

import api from './api';



export const setUserInitialized = data => (
  dispatch => dispatch({
    type: Types.USER_LOADED,
    data,
  })
);

export const setUserData = data => (
  dispatch => dispatch({
    type: Types.SET_USER_DATA,
    data,
  })
);

export const setUserName = (name, destination) => (
  (dispatch) => {
    api.nameUser(name, destination);
    dispatch(setUserData({ name, room: destination }));
  }
);

export const setUserRoom = room => (
  (dispatch) => {
    if (room) {
      // clear answers from other room if not re-entering
      dispatch({
        type: Types.ENTER_ROOM,
        roomName: room,
      });
    }

    api.setUserRoom(room);
    dispatch(setUserData({ room }));
  }
);

export const setUserId = id => (
  (dispatch) => {
    dispatch({
      type: Types.SET_USER_ID,
      id,
    });
  }
);

export const registerUser = (user, isInitialized)  =>  (
  (dispatch) => api.registerUser(dispatch, user, isInitialized)
);

export const timerTick = timeLeft => ({
  type: Types.TICK,
  timeLeft,
});


export const startTimer = () => ({
  type: Types.START_TIMER,
});

export const stopTimer = () => ({
  type: Types.STOP_TIMER,
});


export const clearAnswers = () => ({
  type: Types.CLEAR_ANSWERS,
});
