import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import styles from 'Styles/animations.scss';


const Pulse = ({ on = true, delay, children }) => (
  <div>
    {children}
    {on && (
      <div styleName="pulse" className="animation--pulse" style={{ animationDelay: `${delay + 2000}ms` }} />
    )}
    {on && (
      <div styleName="pulse" className="animation--pulse" style={{ animationDelay: `${delay}ms` }} />
    )}
  </div>
);

Pulse.propTypes = {
  delay: PropTypes.number,
};

export default cssModules(Pulse, styles);
