import React from "react";
import { Router, Route } from "react-router-dom";
import { createHistory } from "react-router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import History from "Util/History";
import configureStore from "../configureStore";

import Landing from "Components/pages/Landing";
import Lobby from "Components/pages/Lobby";
import Spectate from "Components/pages/Spectate";
import ChatContainer from "Components/pages/Chat/ChatContainer";
import Room from "Components/pages/Room";

import Voting from "./game/Voting";

import SvgMorph from "./utility/SvgMorph";
import Axis from "./utility/Axis";
import ReactGA from "react-ga";

import "../assets/scss/global.scss";
import 'rc-slider/assets/index.css';


const initialState = {
  game: {
    score: 0,
    isTimerRunning: false,
    number: 0,
  },
  user: {},
  global: {
    initialized: false,
  },
};
const { store, persistor } = configureStore(initialState);

function setDocHeight() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight / 100}px`,
  );
}

ReactGA.initialize("UA-43098293-2");

History.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

window.addEventListener("resize", setDocHeight);
window.addEventListener("orientationchange", setDocHeight);

setDocHeight();

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router basename={APP_CONFIG.BASE_PATH} history={History}>
        <div className="app_root">
          <Axis />

          <Route exact path="/" component={Landing} />
          <Route path="/svg" component={SvgMorph} />

          <Route path="/lobby" component={Lobby} />
          <Route path="/vote" component={Voting} />

          <Route path="/room/:id" component={Room} />
          {/* <Route path="/spectate/:roomName" component={Spectate} /> */}

          <ChatContainer />
       
        </div>
      </Router>
    </PersistGate>
  </Provider>
);

export { App, store };
export default App;
