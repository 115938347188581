export default (socket) => ({
  startGame() {
    socket.emit("startGame");
  },
  joinGame() {
    return new Promise((resolve) => {
      socket.emit("attemptJoinGame", (result) => {
        resolve(result);
      });
    });
  },
  subscribeToRoomState(room, cb) {
    socket.on(`updatedRoomState:${room}`, cb);
    socket.emit("getRoomState", { room }, cb);
  },
  unsubscribeFromRoomState(room) {
    socket.off(`updatedRoomState:${room}`);
  },
  subscribeToGameState(room, cb) {
    socket.on("updatedGameState", cb);
    socket.emit("getGameState", { room }, cb);
  },
  unsubscribeFromGameState() {
    socket.off("updatedGameState");
  },
  subscribeToAnswerSubmissions(cb) {
    socket.on("updatedSubmissions", cb);
  },
  unsubscribeFromAnswerSubmissions() {
    socket.off("updatedSubmissions");
  },
  subscribeToVoting(room, cb) {
    socket.emit("getVotingState", { room }, cb);
    socket.on("updatedVoting", cb);
  },
  unsubscribeFromVoting() {
    socket.off("updatedVoting");
  },
  getGameTimeLeft(room, initialTimeCallback) {
    socket.emit("getGameTimeLeft", { room }, initialTimeCallback);
  },
  subscribeToTimer(cb) {
    socket.emit("getGameTimeLeft ", {}, cb);
    socket.on("timerTick", cb);
  },
  unsubscribeFromTimer() {
    socket.off("timerTick");
  },
  getGameState(room, cb) {
    socket.emit("getGameState", { room }, cb);
  },

  setPrivacyLevel(privacyLevel) {
    socket.emit("setPrivacyLevel", privacyLevel);
  },
  setAnonymity(anonymity) {
    socket.emit("setAnonymity", anonymity);
  },
  updateTime(newTime, cb) {
    socket.emit("setGameTime", newTime, cb);
  },
  updateList(newList) {
    socket.emit("setGameList", newList);
  },
  nextGamePhase() {
    socket.emit("nextGamePhase");
  },
  resetScores() {
    socket.emit("resetScores");
  },
  submitAnswers(answers) {
    socket.emit("submitAnswers", answers);
  },
  nextVotingRound() {
    socket.emit("nextVotingRound");
  },
  voteAnswer(data) {
    socket.emit("voteAnswer", data);
  },
  getRooms(cb) {
    socket.emit("getRooms", {}, cb);
  },
  getLobby(cb) {
    socket.emit("getLobby", {}, cb);
  },
  changeGameMode(cb) {
    socket.emit("changeGameMode");
  },
  subscribeToLobby(cb) {
    socket.on("updatedLobbyState", cb);
    socket.emit("getLobbyState", {}, cb);
    return () => {
      // console.log('unsubscribing from lobby updates');
      socket.off("updatedLobbyState");
    };
  },
  makeHost(id) {
    socket.emit("makeHost", id);
  },
  sendKickUser(room, id) {
    socket.emit("kickUser", room, id);
  },
});
