import React from 'react';
import cssModules from 'react-css-modules';

import Input from 'Components/ui/Input';
import Button from 'Components/ui/Button';

import styles from 'Styles/ui/input-button.scss';

const InputButton = ({ inputProps = {}, buttonProps = {}, buttonText = 'Next' }) => (
  <div styleName="input-button">
    <div styleName="input">
      <Input {...inputProps} />
    </div>
    <div styleName="button">
      <Button {...buttonProps}>{buttonText}</Button>
    </div>
  </div>
);

export default cssModules(InputButton, styles);
