import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import InputField from 'Components/ui/InputField';

import styles from 'Styles/pages/categories.scss';

class CategoriesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedOn: -1,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  onFocus(i) {
    setTimeout(() => {
      this.setState({
        focusedOn: i,
      });
    }, 1);
  }
  onBlur(i) {
    const { focusedOn } = this.state;
    if (focusedOn === i) {
      this.setState({
        focusedOn: -1,
      });
    }
  }
  handleKeyUp(e) {
    const { focusedOn } = this.state;
    if (e.which === 13 || e.which === 40 || e.which === 38) {
      const nextField = (e.which === 38) ? (focusedOn + 11) % 12 : (focusedOn + 1) % 12;
      document.getElementById(`rbw-category-input-${nextField}`).focus();
      document.getElementById(`rbw-category-input-${nextField}`).select();
    }
  }
  render() {
    const { words, gameId, letter } = this.props;
    const { focusedOn } = this.state;
    return (
      <div>
        {words.map((category, i) => (
          <div key={`${category}${i}`} styleName={`category-wrap ${i === focusedOn ? 'is-focused' : ''}`}>
            <div styleName="number">
              <div styleName="number-inner">
                {(`0${i + 1}`).slice(-2)}
              </div>
            </div>
            <div styleName="right">
              <div styleName="category">
                {category}
              </div>
              <div styleName="input-wrap">
                <InputField
                  onKeyUp={this.handleKeyUp}
                  onFocus={() => this.onFocus(i)}
                  onBlur={() => this.onBlur(i)}
                  id={`rbw-category-input-${i}`}
                  name={`${gameId}-${i}`}
                  namespace={gameId}
                  placeholder={letter}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

CategoriesView.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string),
};

export default cssModules(CategoriesView, styles, { allowMultiple: true });
