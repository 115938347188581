import Types from 'Actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case Types.ENTER_ROOM:
      if (action.roomName !== state.roomName) {
        return {
          ...state,
          roomName: action.roomName,
        };
      }
      return state;
    case Types.CHANGE_INPUT:
      return {
        ...state,
        [action.namespace]: {
          ...state[action.namespace],
          [action.name]: action.value,
        },
      };
    case Types.CLEAR_ANSWERS:
      return {};
    default:
      return state;
  }
}
