import ololog from 'ololog';
import bullet from 'string.bullet';
import ansicolor from 'ansicolor';
import stringify from  'string.ify';

const noop = () => {};
let log = noop;

ansicolor.rgb.lightGray = [240, 240, 240];
ansicolor.rgb.yellow = [255, 226, 0];
ansicolor.rgb.lightYellow = [255, 240, 140];
ansicolor.rgb.lightBlue = [230, 230, 255];

const chromeLog = (s) => {
  return ansicolor.parse(s).asChromeConsoleLogArguments;
};
const logAppendDev = (...arr) => {
  return [...chromeLog(ansicolor.darkGray.bgLightYellow('dev')), ...arr];
};

const logSpread = (arr = []) => {
  console.log(...arr);
};


if (APP_CONFIG.debug) {
  log = (...args) => {
    if (args.length > 1) {
      const name = args[0];
      const rest = args.slice(1);
      if (args[0].slice(-1) === '(') {
        if (rest.length > 1) {
          const variable = rest[0];
          const data = rest.slice(1);
          logSpread(logAppendDev(`${name}{ ${variable}:`, ...data, '})'));
        } else {
          logSpread(logAppendDev(`${name}`, ...rest, ')'));
        }
      } else {
        logSpread(logAppendDev(`${name} ⟶`, ...rest));
      }
    } else {
      logSpread(logAppendDev(...args));
    }
  };
}


export const createDevLog = (tag) => {
  if (APP_CONFIG.debug) {
    const logAppendTag = (...arr) => {
      return [...chromeLog(ansicolor.darkGray.bgLightYellow('dev') + ' ' + ansicolor.blue.bgLightBlue(tag)), ...arr];
    };
    return (...args) => {
      if (args.length > 1) {
        const name = args[0];
        const rest = args.slice(1);
        if (args[0].slice(-1) === '(') {
          if (rest.length > 1) {
            const variable = rest[0];
            const data = rest.slice(1);
            logSpread(logAppendTag(`${name}{ ${variable}:`, ...data, '})'));
          } else {
            logSpread(logAppendTag(`${name}`, ...rest, ')'));
          }
        } else {
          logSpread(logAppendTag(`${name} ⟶`, ...rest));
        }
      } else {
        logSpread(logAppendTag(...args));
      }
    };
  }
  return () => {
    return noop;
  };
};

export const logJson = (args) => {
  if (!APP_CONFIG.debug) {
    return;
  }
  console.log(stringify.configure({
    fancy: false,
    maxStringLength: 20,
  })(args));
};



export default log;
