import React from 'react';

const svgIcons = require.context('../../assets/svg', false, /.*\.svg$/);

const requireAll = requireContext => requireContext.keys().map(requireContext);

const icons = requireAll(svgIcons)
  .reduce((state, spriteSymbol) => {
    const id = spriteSymbol.default.id.split('-usage')[0];
    return {
      ...state,
      [id]: {
        id: `#${id}`,
        viewBox: spriteSymbol.default.viewBox || '',
      },
    };
  }, {},
  );

const SvgIcon = ({ glyph }) => (
  <svg viewBox={icons[glyph].viewBox}>&nbsp;
    <use xlinkHref={icons[glyph].id} />&nbsp;
  </svg>
);


export default SvgIcon;
