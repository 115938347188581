import Types from './types';
import localForage from 'localforage';

export default socket => ({
  sendChatMessage(message, name, id) {
    // console.log('sendChatMessage', message, name, id);
    if (name && message) {
      socket.emit('sendChatMessage', {
        name,
        text: message,
        time: (new Date()).getTime(),
        id,
      });
    } else {
      console.error('User with no name tried to chat');
      localForage.clear();
      alert('something went wrong, please refresh.');
    }
  },
  subscribeToChat(cb, alertCb) {
    socket.on('gameAlert', alertCb);
    socket.on('newChatMessage', cb);
  },
  unsubscribeChat() {
    socket.off('gameAlert');
    socket.off('newChatMessage');
  },
});


export const addToChatCache = message => (
  dispatch => dispatch({
    type: Types.ADD_CHAT_CACHE,
    message,
  })
);

export const getChatCache = (delta = 0, reset = false) => (
  (dispatch, getState) => {
    const { chat } = getState();
    const { chatCache, cursor } = chat;
    let tempCursor = cursor + delta;
    if (reset) {
      tempCursor = 0;
    } if (chatCache.length === 0) {
      tempCursor = 0;
    } else if (chatCache.length <= tempCursor) {
      tempCursor = 0;
    } else if (tempCursor < 0) {
      tempCursor = chatCache.length - 1;
    }
    return dispatch({
      type: Types.SET_CHAT_CACHE_CURSOR,
      cursor: tempCursor,
    });
  }
);
