// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".room-controls__wrap__3ou_y {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 2rem;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 1000; }\n  @media (min-width: 896px) {\n    .room-controls__wrap__3ou_y {\n      left: 2rem;\n      right: 2rem; } }\n\n.room-controls__room-name__1XSLU {\n  color: #fff;\n  font-family: 'rbw-mono';\n  display: inline;\n  background: #0000a9;\n  letter-spacing: 0.01rem;\n  padding: 0.1rem 0.4rem 0.1rem;\n  text-transform: uppercase;\n  margin-right: 1rem;\n  height: 2rem; }\n\n.room-controls__buttons__10yVP {\n  display: flex;\n  flex-direction: row;\n  line-height: 2rem;\n  padding: 0 0.5rem; }\n  .room-controls__buttons__10yVP button {\n    display: inline-block;\n    height: 2rem; }\n\n.room-controls__player-count__1luPM {\n  color: #fff;\n  padding-right: 3px; }\n", ""]);
// Exports
exports.locals = {
	"wrap": "room-controls__wrap__3ou_y",
	"room-name": "room-controls__room-name__1XSLU",
	"buttons": "room-controls__buttons__10yVP",
	"player-count": "room-controls__player-count__1luPM"
};
module.exports = exports;
