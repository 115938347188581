import React from "react";
import { connect } from "react-redux";
import api from "Actions/api";
import PropTypes from "prop-types";
// submits my answers (once), and shows who we're waiting for

import devlog from "Util/devlog";
class AnswerCollector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submissions: [],
    };
  }
  componentWillMount() {
    if (Object.keys(this.props.answers).length) {
      this.submitAnswers(this.props.answers, this.props.gameId);
    }
    api.subscribeToAnswerSubmissions((newSubmissions) => {
      this.setState({
        submissions: newSubmissions,
      });
    });
  }

  componentWillUnmount() {
    api.unsubscribeFromAnswerSubmissions();
  }
  submitAnswers(_answers = {}, gameId) {
    devlog("submitting answers", _answers);
    const answers = [];
    const uniqueAnswers = { "": true };
    for (let i = 0; i < 12; i += 1) {
      const answer = (_answers[`${gameId}-${i}`] || "").trim();
      if (answer in uniqueAnswers || answer.length <= 1) {
        answers.push("");
      } else {
        answers.push(`${answer}`.slice(0, 200));
      }
      uniqueAnswers[answer] = true;
    }
    api.submitAnswers(answers);
  }

  render() {
    return (
      <div>
        <h1>Collected Answers from:</h1>
        <br />
        {this.state.submissions.map((id) => (
          <div key={id}>
            {id in this.props.players
              ? this.props.players[id].name
              : `unknown: ${id}?`}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ user, answers }) => {
  if (user.in_game && user.gameId) {
    return {
      answers: answers[user.gameId],
      gameId: user.gameId,
    };
  }
  return {
    answers: {},
  };
};

AnswerCollector.propTypes = {
  answers: PropTypes.object, // eslint-disable-line
};

AnswerCollector.defaultProps = {
  answers: {},
};

export default connect(mapStateToProps)(AnswerCollector);
