// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toggle__toggle-wrap__1C_FL.toggle__on__2V9tR {\n  background: transparent; }\n  .toggle__toggle-wrap__1C_FL.toggle__on__2V9tR .toggle__toggle-track__3iR-b {\n    background: blue; }\n  .toggle__toggle-wrap__1C_FL.toggle__on__2V9tR .toggle__toggle-inner__1N6Hk > div:first-child {\n    color: #a2a4a5; }\n  .toggle__toggle-wrap__1C_FL.toggle__on__2V9tR .toggle__toggle-circle___5JKR {\n    transform: translate(2rem, 0); }\n\n.toggle__toggle-wrap__1C_FL.toggle__off__3Fv37 {\n  background: transparent; }\n  .toggle__toggle-wrap__1C_FL.toggle__off__3Fv37 .toggle__toggle-track__3iR-b {\n    background: #202020; }\n  .toggle__toggle-wrap__1C_FL.toggle__off__3Fv37 .toggle__toggle-inner__1N6Hk > div:last-child {\n    color: #a2a4a5; }\n  .toggle__toggle-wrap__1C_FL.toggle__off__3Fv37 .toggle__toggle-circle___5JKR {\n    transform: none; }\n\n.toggle__toggle-wrap__1C_FL .toggle__toggle__2V_Oe {\n  padding: 0.5rem 0.8rem;\n  background: transparent;\n  color: #fff;\n  box-sizing: border-box;\n  font-family: 'rbw-sans';\n  font-weight: bold; }\n  .toggle__toggle-wrap__1C_FL .toggle__toggle__2V_Oe .toggle__toggle-inner__1N6Hk {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    box-sizing: border-box;\n    font-size: 1.3rem; }\n  .toggle__toggle-wrap__1C_FL .toggle__toggle__2V_Oe .toggle__toggle-track__3iR-b {\n    width: 4rem;\n    height: 2rem;\n    border-radius: 1000px;\n    border: 2px solid #e2e2e2;\n    position: relative;\n    box-sizing: content-box;\n    margin: 0 1rem; }\n    .toggle__toggle-wrap__1C_FL .toggle__toggle__2V_Oe .toggle__toggle-track__3iR-b .toggle__toggle-circle___5JKR {\n      height: 2rem;\n      width: 2rem;\n      border-radius: 1000px;\n      background: #fff;\n      position: absolute;\n      top: 0;\n      left: 0;\n      bottom: 0;\n      box-sizing: border-box;\n      transition: 200ms ease; }\n", ""]);
// Exports
exports.locals = {
	"toggle-wrap": "toggle__toggle-wrap__1C_FL",
	"on": "toggle__on__2V9tR",
	"toggle-track": "toggle__toggle-track__3iR-b",
	"toggle-inner": "toggle__toggle-inner__1N6Hk",
	"toggle-circle": "toggle__toggle-circle___5JKR",
	"off": "toggle__off__3Fv37",
	"toggle": "toggle__toggle__2V_Oe"
};
module.exports = exports;
