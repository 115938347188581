import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';

import styles from 'Styles/animations.scss';


const FadeAway = ({ on = true, delay, style, children }) => (
  on ? (
    <div styleName="fade-away" className="animation--fade-away" style={{ ...style, animationDelay: `${delay}ms` }}>{children}</div>
  ) : (
    <div style={style}>
      {children}
    </div>
  )
);

FadeAway.propTypes = {
  delay: PropTypes.number,
  style: PropTypes.object,
};


export default cssModules(FadeAway, styles);
