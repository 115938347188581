import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import cssModules from "react-css-modules";
import LetterView from "Components/game/Categories/LetterView";
import InputField from "Components/ui/InputField";
import CategoriesView from "Components/game/Categories/CategoriesView";
import VotingView from "Components/game/Voting/VotingView";

import styles from "Styles/pages/rulebook.scss";

class RuleBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [
        {
          valid: true,
          answer: "Audi",
          votes: {
            rulebook: 1,
          },
          owner: "rulebook1",
        },
        {
          valid: true,
          answer: "Aston Martin",
          votes: {},
          owner: "rulebook2",
        },
        {
          valid: true,
          answer: "Apple Computer",
          votes: {
            rulebook: -1,
            rulebook2: -1,
          },
          owner: "rulebook3",
        },
        {
          valid: true,
          answer: "A school bus",
          votes: {
            rulebook: -1,
          },
          owner: "rulebook4",
        },
      ],
    };
    this.handleVote = this.handleVote.bind(this);
  }
  handleVote(number, yesOrNo) {
    const answers = this.state.answers.slice(0);
    answers[number].votes["rulebookUser"] = yesOrNo === "y" ? 1 : -1;
    this.setState({
      answers,
    });
  }
  render() {
    return (
      <div styleName="rulebook">
        <div style={{ height: "1rem" }} />

        <div className="rbw-text">The Really Boring Website</div>
        <h1>Rulebook</h1>
        <hr />
        <p className="rbw-text-l">
          Each round you will get a letter and 12 categories.
        </p>
        {/* <LetterView letter="A" /> */}
        <p className="rbw-text-l">
          Answer each category with a word or phrase starting with that letter.
        </p>
        <div styleName="bump-left">
          <CategoriesView
            letter={"A"}
            words={["Cars", "Colors"]}
            gameId="rulebook"
          />
        </div>
        <div className="p">
          <div style={{ height: "0.5rem" }} />
          <div>
            Note: You are not allowed to use the same word more than once per
            round.
          </div>
        </div>
        <div style={{ height: "1rem" }} />

        <div className="p">
          <div className="rbw-text-l">
            Then, players vote on whether the answer is valid
          </div>
        </div>

        <VotingView
          canVote
          myId={"rulebookUser"}
          onVote={this.handleVote}
          answers={this.state.answers}
        />
        <div className="p">
          <div style={{ height: "0.5rem" }} />
          <div>
            As long as an answer has a non-negative score (it is green), the
            player will receive 1 point for that category.
          </div>
        </div>
        <p className="rbw-text-l">
          Try to be unique! If another player has the same answer, neither
          player will get points for that category.
        </p>
        <VotingView
          canVote
          onVote={() => {}}
          answers={[
            {
              valid: false,
              answer: "Acura",
              votes: {},
              owner: "rulebook1",
            },
            {
              valid: false,
              answer: "Acura",
              votes: {},
              owner: "rulebook2",
            },
          ]}
        />
        <div style={{ height: "3rem" }} />

        {/* <LetterView letter='A' /> */}
        <div>Guidelines for</div>
        <h1>Voting</h1>
        <hr />
        <p className="rbw-text-l" styleName="important">
          For the most part, what counts as a valid answer is subjective and
          depends on the people you are playing with.
        </p>
        {/* <div style={{ height: "0.5rem" }} /> */}
        {/* <p className="rbw-text-l" styleName="important">
          People can vote how they want.
        </p> */}
        <div style={{ height: "0.5rem" }} />
        {/* <div className="p">
          <div className="rbw-text-l">
            You can't use the same word twice in one round.
          </div>
        </div>
        <div style={{ height: '1rem' }}/> */}

        <div className="p">
          <div className="rbw-text-l">
            The word <i>The</i> does not count for the letter&nbsp;T.
          </div>
          <div style={{ height: "0.5rem" }} />
          <div>
            — For example, <i>The Matrix</i> is valid for{" "}
            <strong>Movies / M </strong> but not{" "}
            <strong>Movies&nbsp;/&nbsp;T</strong>.
          </div>
          <div style={{ height: "0.5rem" }} />
          <div>
            — The same is true for the words <i>A</i> and <i>An</i>
          </div>
        </div>
        <div style={{ height: "2rem" }} />
        <div className="p">
          <div className="rbw-text-l">
            You can begin with a person's last&nbsp;name.
          </div>
          <div style={{ height: "0.5rem" }} />
          <div>
            — For example, <i>Depp, Johnny</i> is valid for{" "}
            <strong>Celebrities / D</strong> .
          </div>
        </div>
        <div style={{ height: "2rem" }} />

        <div className="p">
          <div className="rbw-text-l">
            Avoid descriptive adjectives unless they are part of a common
            phrase.
          </div>
          <div style={{ height: "0.5rem" }} />
          <div>
            — Ask yourself: is the word that follows the adjective doing most of
            the heavy lifting?
          </div>
          <div style={{ height: "0.5rem" }} />
          <div>
            — For example, <strong>Vegetables&nbsp;/&nbsp;G</strong>: Green
            Beans vs Green Lettuce.
          </div>
        </div>
        <div style={{ height: "3rem" }} />

        <hr />
        <p className="rbw-text-l">
          E-mail me at{" "}
          <a href="mailto:wendygarfo@gmail.com">wendygarfo@gmail.com</a> if you
          have additional questions. Thanks for playing!
        </p>
      </div>
    );
  }
}

const mapStateToProps = ({ user, chat, game }) => ({
  name: user.name,
});
const mapDispatchToProps = {};
const withStyles = cssModules(RuleBook, styles, { allowMultiple: true });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles);
