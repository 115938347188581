import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import api from "Actions/api";
import Pictionary from "Components/Pictionary";

import GameView from "./GameView";
import config from "../gameConfig";
import devlog from "Util/devlog";
/* eslint-disable */
/*
  How does the game work?


  Timer ends!
  Collect answers from everyone, (remove empty string and just the letter)

  anonymous voting
  <Voting />
  <ScoreBoard />

  and then game over
  <Results /> / waiting

  Others
  <LeaveRoomWarning />
  <SpectatorWarning />

 */
/* eslint-enable */

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: config.PHASES.LOADING,
    };
    this.syncState = this.syncState.bind(this);
    this.syncVotingState = this.syncVotingState.bind(this);
    this.subscribed = false;
  }
  componentWillMount() {
    console.log("subscribting to", this.props.roomName);
    if (this.props.roomName) {
      this.subscribed = true;
      api.subscribeToGameState(this.props.roomName, this.syncState);
      api.subscribeToVoting(this.props.roomName, this.syncVotingState);
    }
  }
  componentWillReceiveProps(newProps) {
    if (!this.subscribed && !this.props.roomName && newProps.roomName) {
      this.subscribed = true;
      api.subscribeToGameState(newProps.roomName, this.syncState);
      api.subscribeToVoting(newProps.roomName, this.syncVotingState);
    }
  }
  componentWillUnmount() {
    api.unsubscribeFromGameState();
    api.unsubscribeFromVoting();
  }
  syncState(gameState) {
    devlog("New Game State", gameState);
    console.log(gameState);
    this.setState({
      phase: gameState.phase,
      words: gameState.words,
      letter: gameState.letter,
      players: gameState.players,
      // host: gameState.host,
      // isPrivate: gameState.private,
      // isDefault: gameState.default,
      gameTime: gameState.gameTime,
      // isAnonymous: gameState.isAnonymous,
      // playerLimit: gameState.playerLimit,
      // roundNonce: gameState.roundNonce,
      // playersTurn: gameState.playersTurn,
    });
  }
  syncVotingState(votingState) {
    devlog("New voting State", votingState);

    this.setState({
      round: votingState.round,
      answers: votingState.answers,
    });
  }
  render() {
    const {
      phase,
      words,
      letter,
      round,
      answers,

      players,

      gameTime,
      isAnonymous,
    } = this.state;
    const { gameMode, roundNonce, playersTurn, host } = this.props;
    return gameMode === 1 ? (
      <GameView
        phase={phase}
        words={words}
        letter={letter}
        round={round}
        answers={answers}
        scores={this.props.scores}
        lastScores={this.props.lastScores}
        players={players}
        host={host}
        isPrivate={this.props.settings.isPrivate}
        members={this.props.members}
        uid={this.props.uid}
        gameTime={gameTime}
        isAnonymous={this.props.settings.isVotingAnonymous}
        isDefault={this.props.settings.isDefaultRoom}
      />
    ) : gameMode === 2 ? (
      <>
        <Pictionary
          playersTurn={this.props.playersTurn}
          uid={this.props.uid}
          roomName={this.props.roomName}
          roundNonce={this.props.roundNonce}
          members={this.props.members}
          scores={this.props.scores}
          lastScores={this.props.lastScores}
          host={this.props.host}
        />
      </>
    ) : null;
  }
}
Game.propTypes = {
  roomName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const mapStateToProps = ({ user }) => ({
  roomName: user.room,
  uid: user.id,
});
export default connect(mapStateToProps)(Game);
