import React from 'react';
import anime from 'animejs';

const ampersandPath = 'M1044 1434v-246l-563 -1188h-317l553 1157h-656v277h983z';

const ninePath = 'M584 461q-193 0 -342 151q-150 152 -150 351q0 211 154 356q153 145 389 145q235 0 389 -145t154 -356q0 -86 -31 -173q-31 -88 -62 -131l-30 -45l-400 -614h-317l307 471q-26 -10 -61 -10zM389 962q0 -100 70 -168q69 -67 176 -67q106 0 176 67q70 68 70 168q0 101 -70 168q-70 68 -176 68q-107 0 -176 -68q-70 -67 -70 -168z';

class SvgMorph extends React.Component {
  componentDidMount() {
    this.animate();
  }
  animate() {
    const animation = anime({
      targets: ['.animate-svg-path path'],
      easing: 'easeOutCirc',
      duration: 5000,
      d: [{
        value: ninePath,
      }, {
        value: ampersandPath,
      }],
      loop: true,
    });
    console.log(animation);
  }
  render() {
    return (
      <svg
        className="animate-svg-path"
        viewBox="-358 -471 2945 2007"
        style={{
          fill: 'blue',
          transform: 'rotateX(180deg)',
        }}
      >
        <g>
          <path
            d={ampersandPath}
          />
        </g>
      </svg>
    );
  }
}

export default SvgMorph;
