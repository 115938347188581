import Types from "Actions/types";
import UserService from "Util/UserService";

const { STATES } = UserService;

export default function (state = {}, action) {
  switch (action.type) {
    case Types.SET_USER_DATA:
      return {
        ...state,
        ...action.data,
      };
    case Types.SET_USER_ID:
      return {
        ...state,
        id: action.id,
      };
    case Types.ENTER_ROOM:
      if (action.roomName !== state.roomName) {
        return {
          ...state,
          room: action.roomName,
        };
      }
      return state;
    case Types.START_GAME:
      return state;
    default:
      return state;
  }
}
