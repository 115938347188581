import React from 'react';
import PropTypes from 'prop-types';
import cssModules from 'react-css-modules';
import styles from 'Styles/pages/categories.scss';

const LetterView = ({ letter }) => (
  <div styleName="letter">
    {letter}
  </div>
);
LetterView.propTypes = {
  letter: PropTypes.string,
};
export default cssModules(LetterView, styles);
