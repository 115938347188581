import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import styles from 'Styles/animations.scss';


const FadeIn = ({ delay, style, children }) => (
  <div styleName="fade-in" className="animation--fade-in" style={{ ...style, animationDelay: `${delay}ms` }}>{children}</div>
);

FadeIn.propTypes = {
  delay: PropTypes.number,
  style: PropTypes.object,
};


export default cssModules(FadeIn, styles);
