// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".timer__timer-wrap__27lAi {\n  font-family: 'rbw-super-mono';\n  color: #f3f3f3;\n  font-size: 2rem;\n  position: absolute;\n  z-index: 20;\n  top: 0;\n  right: 2rem;\n  height: 8rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-bottom: 2px transparent; }\n  .timer__timer-wrap__27lAi .timer__icon-wrap__tmtn4 {\n    height: 1.5rem; }\n  .timer__timer-wrap__27lAi svg {\n    height: 1.5rem;\n    width: 1.5rem;\n    fill: #f3f3f3;\n    margin-right: 1rem;\n    opacity: 0.4;\n    vertical-align: top;\n    line-height: 0; }\n  .timer__timer-wrap__27lAi span {\n    opacity: 0.4;\n    transform: translate(0px, -2px); }\n  .timer__timer-wrap__27lAi .timer__minutes__3I34P,\n  .timer__timer-wrap__27lAi .timer__seconds__2c5nf {\n    display: inline-block; }\n  .timer__timer-wrap__27lAi .timer__minutes__3I34P {\n    text-align: right; }\n", ""]);
// Exports
exports.locals = {
	"timer-wrap": "timer__timer-wrap__27lAi",
	"icon-wrap": "timer__icon-wrap__tmtn4",
	"minutes": "timer__minutes__3I34P",
	"seconds": "timer__seconds__2c5nf"
};
module.exports = exports;
