import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import cssModules from "react-css-modules";
import PropTypes from "prop-types";
import Button from "Components/ui/Button";
import ClipboardJS from "clipboard";

import { setUserName, setUserRoom } from "Actions";
import styles from "Styles/pages/room-controls.scss";
const isValidUserName = (s = "") =>
  s && s.trim() && s.trim().length < 32 && /^[_a-zA-Z\d .]+$/g.test(s);

class RoomControls extends React.Component {
  constructor(props) {
    super(props);
    this.leaveRoom = this.leaveRoom.bind(this);
    this.changeName = this.changeName.bind(this);
  }
  componentDidMount() {
    this.clipboard = new ClipboardJS("#copy-room-text");

    this.clipboard.on("success", function (e) {
      alert('copied link');
      e.clearSelection();
    });
  }
  leaveRoom() {
    this.props.history.push("/lobby");
  }
  changeName() {
    const name = prompt("New Name:");
    if (isValidUserName(name)) {
      this.props.dispatch(setUserName(name.trim(), this.props.name));
    }
  }

  render() {
    const { audienceNumber, playerNumber, name } = this.props;
    return (
      <div styleName="wrap">
        <div styleName="buttons">
          <div>Room:</div>
          <div styleName="room-name">{name}</div>

          <Button onClick={this.leaveRoom}>Leave Room</Button>
          <Button onClick={this.changeName}>Change Name</Button>
          {/* <button
            id="copy-room-text"
            className="rbw-text"
            data-clipboard-text={window ? window.location.href : ""}
          >
            Invite
          </button> */}
        </div>

        <div styleName="player-count">
          In room: {audienceNumber + playerNumber}
        </div>
      </div>
    );
  }
}

RoomControls.propTypes = {
  history: PropTypes.object,
  audienceNumber: PropTypes.number,
  playerNumber: PropTypes.number,
};

const withStyles = cssModules(RoomControls, styles, { allowMultiple: true });
const connectedWithRouter = withRouter(withStyles);
export default connect()(connectedWithRouter);
