// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".members__rulebook__1IbvT {\n  border: 2px solid #f3f3f3;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  top: 0;\n  background: #202020;\n  padding: 1rem 0;\n  overflow: scroll;\n  padding-bottom: 4rem; }\n  .members__rulebook__1IbvT::-webkit-scrollbar {\n    display: none; }\n  .members__rulebook__1IbvT h1 {\n    font-size: 4rem;\n    margin: 0;\n    margin-bottom: 1rem;\n    padding: 0 2rem; }\n  .members__rulebook__1IbvT .members__bump-left__3HtnM {\n    margin-left: -1rem; }\n  .members__rulebook__1IbvT .members__important___f9Tx {\n    background: rgba(243, 243, 243, 0.9);\n    color: #202020;\n    padding: 1rem; }\n  .members__rulebook__1IbvT a {\n    color: #f3f3f3; }\n  .members__rulebook__1IbvT .members__p__1QqI8 {\n    margin: 1em 0; }\n", ""]);
// Exports
exports.locals = {
	"rulebook": "members__rulebook__1IbvT",
	"bump-left": "members__bump-left__3HtnM",
	"important": "members__important___f9Tx",
	"p": "members__p__1QqI8"
};
module.exports = exports;
