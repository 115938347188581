import React from "react";
import cssModules from "react-css-modules";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from "Styles/pages/lobby.scss";

import Input from "Components/ui/Input";
import SystemButton from "Components/ui/SystemButton";
import InputButton from "Components/ui/InputButton";

import { FadeUp, FadeAway, FadeRight } from "Components/animations";
import { registerUser, setUserRoom, setUserName, stopTimer } from "Actions";

import Chat from "Components/pages/Chat";

import api from "Actions/api";
import emitter from "Util/emitter";

import { createDevLog } from "Util/devlog";

const devlog = createDevLog("Lobby");
const MAX_ROOM_PLAYERS = 6;

const displayGamePhase = (phase) => {
  switch (phase) {
    case "STARTING":
    case "ANSWERING":
      return "Answering";
    case "WAIT_FOR_ANSWERS":
    case "VOTING":
      return "Voting Stage";
    case "WAITING":
    default:
      return "Ready to Play";
  }
};
const isValidRoomName = (s) => /^[_a-zA-Z\d-]+$/g.test(s);

class Lobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publicRooms: [],
      leaving: false,
      nextRoom: -1,
    };
    this.goToRoom = this.goToRoom.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.syncState = this.syncState.bind(this);

    this.cleanUp = [];
  }
  componentDidMount() {
    if (!this.props.user.name) {
      document.location.href = "/";
    }

    this.props.stopTimer();
    this.props
      .dispatch(registerUser(this.props.user, this.props.initialized))
      .then(() => {
        this.props.dispatch(setUserRoom("general"));
        this.cleanUp.push(api.subscribeToLobby(this.syncState));
        emitter.emit("alert", {
          message: "Joined Room",
          variable: "Lobby",
          type: "info",
        });
      });
  }

  componentWillUnmount() {
    this.cleanUp.forEach((fn) => {
      if (typeof fn === "function") {
        fn();
      }
    });
  }

  syncState({ publicRooms, lobbyMembers, serverStatus, updates }) {
    const nextState = {
      serverStatus,
    };
    if (publicRooms) {
      nextState.publicRooms = publicRooms;
    }
    if (lobbyMembers) {
      nextState.lobbyMembers = lobbyMembers;
    }
    if (updates && Object.keys(updates).length) {
      const newRooms = this.state.publicRooms.slice(0);
      Object.keys(updates).forEach((key) => {
        let roomIndex = newRooms.findIndex(({ name }) => name === key);
        const changes = updates[key];
        newRooms[roomIndex] = {
          ...newRooms[roomIndex],
          ...changes,
        };
      });
      nextState.publicRooms = newRooms;
    }

    devlog("syncState", nextState);
    this.setState(nextState);
  }
  goToRoom(roomName, index) {
    this.setState(
      {
        leaving: true,
        nextRoom: index,
      },
      () => {
        setTimeout(() => {
          this.props.history.push(`/room/${roomName}`);
        }, 300);
      },
    );
  }
  handleSubmit(e) {
    e.preventDefault();

    let roomName = document.getElementById("private-room-input").value || "";
    roomName = encodeURIComponent(roomName.replace(/\s/g, "_").slice(0, 80));
    if (
      roomName &&
      roomName.trim() &&
      isValidRoomName(roomName) &&
      roomName.trim().toLowerCase() !== "general"
    ) {
      this.goToRoom(roomName.trim().toLowerCase(), -1);
    } else {
      alert("Invalid Room Name");
    }
  }
  render() {
    const { user } = this.props;
    const { publicRooms, leaving, nextRoom, lobbyMembers } = this.state;
    return (
      <div styleName="landing">
        <div styleName="main-outer">
          <div styleName="main-full">
            <div
              styleName="logo"
              className={`rbw-text-l noselect ${leaving ? "fade-up-out" : ""}`}
            >
              Welcome, <span styleName="user-name">{user.name}</span>!
            </div>
            <div styleName="refresh-button">
              <SystemButton onClick={this.getRoomData}>
                <div>
                  {lobbyMembers} player{lobbyMembers === 1 ? "" : "s"} in Lobby
                </div>
              </SystemButton>
            </div>
            <div styleName="rooms">
              <div
                styleName="private-room-input"
                className={
                  nextRoom === -1
                    ? "morph-into-room"
                    : leaving
                    ? "fade-down-out"
                    : "fade-up"
                }
              >
                <div styleName="title">
                  <div className="rbw-text-l">Join or Create a Room </div>
                  <div className="rbw-text" styleName="subtitle">
                    {" "}
                    24 player maximum
                  </div>
                </div>
                <div style={{ height: "1rem" }}></div>

                <div style={{ height: "1rem" }}></div>
                <form onSubmit={this.handleSubmit}>
                  <div styleName="input-wrap">
                    <InputButton
                      inputProps={{
                        id: "private-room-input",
                        placeholder: "Room Name",
                      }}
                      buttonProps={{ type: "submit" }}
                      buttonText="Enter"
                    />
                  </div>
                </form>
                <br />
                <br />

                <div className="rbw-text-l">Or Join a Public Room</div>
              </div>

              {publicRooms.length ? (
                publicRooms
                  .sort((a, b) => a.playerLimit - b.playerLimit)
                  .map((room, i) => (
                    <div
                      styleName={"button-animation"}
                      key={room.name}
                      className={
                        nextRoom === i
                          ? "morph-into-room"
                          : leaving
                          ? "fade-down-out"
                          : "fade-up"
                      }
                      style={{ animationDelay: `${i * 80}ms` }}
                    >
                      <button
                        onClick={() => {
                          if (room.members < room.playerLimit) {
                            this.goToRoom(room.name, i);
                          }
                        }}
                        styleName="room-button"
                      >
                        <div styleName="member-counter">
                          {room.members}/{room.playerLimit} Players
                        </div>
                        <div styleName="room-title">{room.name}</div>
                        <div className="rbw-mono">
                          {displayGamePhase(room.phase)}
                        </div>
                      </button>
                    </div>
                  ))
              ) : (
                <div styleName="loading">Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const withStyles = cssModules(Lobby, styles, { allowMultiple: true });
const connectedWithRouter = withRouter(withStyles);
const mapDispatchToProps = (dispatch) => ({
  stopTimer: () => dispatch(stopTimer),
  dispatch,
});

export default connect(
  (state) => ({
    user: state.user,
    initialized: state.global.initialized,
  }),
  mapDispatchToProps,
)(connectedWithRouter);
