import React, { Component } from "react";
import { connect } from "react-redux";

import api from "Actions/api";
import emitter from "Util/emitter";

import VotingView from "./VotingView";

class Voting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round: props.round,
      answers: props.answers,
    };
  }
  handleVote(index, type) {
    console.log(index, type);
    api.voteAnswer({
      index,
      type,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.round !== this.props.round) {
      this.setState({
        inTransition: true,
        answers: prevProps.answers,
      });
      setTimeout(() => {
        this.setState({
          inTransition: false,
          answers: this.props.answers,
          round: this.props.round,
        });
      }, 800);
    }
  }
  render() {
    const { players, isAnonymous } = this.props;
    const { inTransition, answers } = this.state;
    return (
      <VotingView
        isAnonymous={isAnonymous}
        inTransition={inTransition}
        canVote={this.props.canVote}
        myId={this.props.myId}
        answers={inTransition ? answers : this.props.answers}
        onVote={this.handleVote}
        players={players}
      />
    );
  }
}

const mapStateToProps = ({ user }) => ({
  myId: user.id,
});
export default connect(mapStateToProps)(Voting);
