export default {
  START_APP: 'START_APP',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  START_TIMER: 'START_TIMER',
  STOP_TIMER: 'STOP_TIMER',
  TICK: 'TICK',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_ZIP_DATA: 'SET_ZIP_DATA',
  START_GAME: 'START_GAME',
  END_GAME: 'END_GAME',
  MARK_RESULT_CORRECT: 'MARK_RESULT_CORRECT',

  USER_LOADED: 'USER_LOADED',
  SET_USER_ID: 'SET_USER_ID',
  SET_USER_STATE: 'SET_USER_STATE',

  CHANGE_INPUT: 'CHANGE_INPUT',
  ENTER_ROOM: 'ENTER_ROOM',
  CLEAR_ANSWERS: 'CLEAR_ANSWERS',

  ADD_CHAT_CACHE: 'ADD_CHAT_CACHE',
  SET_CHAT_CACHE_CURSOR: 'SET_CHAT_CACHE_CURSOR',
  CLEAR_CHAT_CACHE: 'CLEAR_CHAT_CACHE',
};
