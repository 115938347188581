// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".input-field__input__23sxB::placeholder {\n  color: #909090; }\n", ""]);
// Exports
exports.locals = {
	"input": "input-field__input__23sxB"
};
module.exports = exports;
