// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".axis__axis__wB6pc {\n  position: fixed;\n  z-index: 22;\n  display: none; }\n  .axis__axis__wB6pc .axis__vaxis__1l-5- {\n    position: fixed;\n    width: 2px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    transform: translate(-1px, 0);\n    top: 0;\n    left: 50%;\n    bottom: 0; }\n  .axis__axis__wB6pc .axis__mmargin1__2klwy {\n    position: fixed;\n    width: 2px;\n    margin-left: -1px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    transform: translate(-1rem, 0);\n    top: 0;\n    left: 50%;\n    bottom: 0; }\n  .axis__axis__wB6pc .axis__mmargin2__3RIlH {\n    position: fixed;\n    width: 2px;\n    margin-left: -1px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    transform: translate(1rem, 0);\n    top: 0;\n    left: 50%;\n    bottom: 0; }\n  .axis__axis__wB6pc .axis__haxis__NOKDP {\n    position: fixed;\n    height: 2px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    transform: translate(0, -1px);\n    top: 50%;\n    left: 0;\n    right: 0; }\n  .axis__axis__wB6pc .axis__lmargin__1rYjC {\n    position: fixed;\n    width: 2px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    top: 0;\n    left: 2rem;\n    bottom: 0; }\n  .axis__axis__wB6pc .axis__rmargin__4Gsuk {\n    position: fixed;\n    width: 2px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    top: 0;\n    right: 2rem;\n    bottom: 0; }\n  .axis__axis__wB6pc .axis__tmargin__2ufwe {\n    position: fixed;\n    height: 2px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    top: 2rem;\n    left: 0;\n    right: 0; }\n  .axis__axis__wB6pc .axis__bmargin__6wpLv {\n    position: fixed;\n    height: 2px;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: -1;\n    bottom: 2rem;\n    top: auto;\n    left: 0;\n    right: 0; }\n", ""]);
// Exports
exports.locals = {
	"axis": "axis__axis__wB6pc",
	"vaxis": "axis__vaxis__1l-5-",
	"mmargin1": "axis__mmargin1__2klwy",
	"mmargin2": "axis__mmargin2__3RIlH",
	"haxis": "axis__haxis__NOKDP",
	"lmargin": "axis__lmargin__1rYjC",
	"rmargin": "axis__rmargin__4Gsuk",
	"tmargin": "axis__tmargin__2ufwe",
	"bmargin": "axis__bmargin__6wpLv"
};
module.exports = exports;
