import React from 'react';
import cssModules from 'react-css-modules';
import styles from 'Styles/utils/axis.scss';

const Axis = ({ styleName = '', children }) => (
  <div styleName="axis">
    <div styleName="vaxis" />
    <div styleName="haxis" />
    <div styleName="rmargin" />
    <div styleName="lmargin" />
    <div styleName="tmargin" />
    <div styleName="bmargin" />

    <div styleName="mmargin1" />
    <div styleName="mmargin2" />

    {/* <div styleName="tripmargin1" />
    <div styleName="tripmargin2" />
    <div styleName="tripmargin3" />
    <div styleName="tripmargin4" /> */}

  </div>
);

export default cssModules(Axis, styles, { allowMultiple: true });
