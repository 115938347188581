import React from "react";
import { connect } from "react-redux";
import cssModules from "react-css-modules";
import PropTypes from "prop-types";
import api from "Actions/api";
import { clearAnswers } from "Actions";
import FadeIn from "Components/animations/FadeIn";
import styles from "Styles/game/voting-rounds.scss";

class VotingRounds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideNext: true,
      round: props.round,
    };
  }
  componentWillMount() {
    this.props.clearAnswers();
    const iAmHost = this.props.myId === this.props.host;
    if (iAmHost) {
      setTimeout(() => {
        this.setState({
          hideNext: false,
        });
      }, 1000);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.round !== this.props.round) {
      this.setState({
        hideNext: true,
      });
      setTimeout(() => {
        this.setState({
          round: this.props.round,
        });
      }, 800);

      const iAmHost = this.props.myId === this.props.host;
      if (iAmHost) {
        setTimeout(() => {
          this.setState({
            hideNext: false,
          });
        }, 1000);
      }
    }
    if (prevProps.host !== this.props.host) {
      const iAmHost = this.props.myId === this.props.host;
      if (iAmHost) {
        this.setState({
          hideNext: false,
        });
      }
    }
  }
  render() {
    const { words } = this.props;
    const { round } = this.state;
    return typeof round === "number" ? (
      <div styleName="voting-controller-wrap">
        <div styleName="voting-round">
          <div styleName="voting-round-round-number">{round + 1}</div>
          <div styleName="voting-round-out-of">
            {" "}
            <span>of</span> 12
          </div>
        </div>
        <div styleName="category">{words[round]}</div>
        {!this.state.hideNext && (
          <div styleName="button">
            <FadeIn>
              <button styleName="button-el" onClick={api.nextVotingRound}>
                <div styleName="text-inner">Next</div>
              </button>
            </FadeIn>
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  }
}

VotingRounds.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string),
  round: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  clearAnswers: PropTypes.func,
};

const mapStateToProps = ({ user }) => ({
  myId: user.id,
});
const withStyles = cssModules(VotingRounds, styles, { allowMultiple: true });
export default connect(mapStateToProps, { clearAnswers })(withStyles);
