import React from "react";
import PropTypes from "prop-types";
import cssModules from "react-css-modules";
// import ThumbsUp from 'Assets/svg/heroicons/thumb-up.svg';
import styles from "Styles/game/voting.scss";
import SvgIcon from "Components/utility/SvgIcon";

const sum = (obj = {}) => Object.values(obj).reduce((a, b) => a + b, 0);
const appendSign = (n) => (n >= 0 ? `+${n}` : `${n}`);
const yesPercentage = (obj = {}) => {
  let yeses = 0;
  let values = Object.values(obj);
  values.forEach((vote) => {
    if (vote === 1) {
      yeses += 1;
    }
  });
  if (!values.length) {
    return "0";
  }
  return `${yeses}/${values.length}`; //`${Math.floor(100 * yeses/values.length)}%`;
};

const VotingView = ({
  canVote,
  myId,
  answers,
  onVote,
  players,
  inTransition = false,
  isAnonymous = true,
}) => (
  <div styleName="wrapper" className="1">
    <div styleName={`answers ${inTransition ? "in-transition" : ""}`}>
      {answers.length ? (
        answers.map((answer, i) => {
          const isPositive = sum(answer.votes) >= 0;
          return (
            <div
              styleName={`answer-wrap ${answer.valid ? "valid" : "invalid"} ${
                isPositive ? "vote-yes" : "vote-no"
              }`}
              key={answer.owner}
            >
              <div styleName="score-banner">
                <div>+1</div>
              </div>
              <div styleName="score-banner-negative">
                <div>0</div>
              </div>
              <div styleName="score">{appendSign(sum(answer.votes))}</div>
              <div styleName="score-percent">{yesPercentage(answer.votes)}</div>

              {!isAnonymous && answer.owner in players ? (
                <div styleName="answer-player">
                  {players[answer.owner].name}:
                </div>
              ) : (
                ""
              )}

              <div styleName="answer-content">{answer.answer}</div>
              {canVote && answer.owner !== myId && answer.valid && (
                <div styleName="vote-interaction-wrap">
                  <div
                    styleName={`vote-press ${
                      answer.votes[myId] === -1 ? "no" : ""
                    } ${answer.votes[myId] === 1 ? "yes" : ""}`}
                  >
                    <div styleName="vote-press-inner" />
                  </div>
                  <div
                    styleName={`left-button ${
                      answer.votes[myId] === -1 ? "pressed" : ""
                    }`}
                  >
                    <button
                      onClick={() => {
                        onVote(i, "n");
                      }}
                    >
                      NO
                    </button>
                  </div>
                  <div
                    styleName={`right-button ${
                      answer.votes[myId] === 1 ? "pressed" : ""
                    }`}
                  >
                    <button
                      onClick={() => {
                        onVote(i, "y");
                      }}
                    >
                      YES
                    </button>
                  </div>
                </div>
              )}
              {answer.owner === myId && (
                <div styleName="vote-interaction-wrap">
                  <div styleName="your-answer" className="rbw-text">
                    YOUR ANSWER
                  </div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div styleName="no-answers">
          <div styleName="no-answers-inner">No Answers.</div>
        </div>
      )}
    </div>
  </div>
);

VotingView.defaultProps = {
  answers: [],
  players: {},
};
VotingView.propTypes = {
  canVote: PropTypes.bool,
  myId: PropTypes.string,
  onVote: PropTypes.func,
};

export default cssModules(VotingView, styles, { allowMultiple: true });
