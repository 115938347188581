import React from 'react';
import CSSModules from 'react-css-modules';
import PropTypes from 'prop-types';
import Scales from '../utility/Scales';


import styles from '../../scss/ui/input.scss';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      error: false,
      disabled: false,
      value: props.defaultValue,
    };
    this.onType = this.onType.bind(this);
    this.el = null;
  }
  onType(e) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e, this.el);
    }
    this.setState({
      value: this.el.value,
    });
  }

  render() {
    const { label, placeholder, type, id, ref } = this.props;
    const { value } = this.state;
    return (
      <div styleName="input-wrap">
        <div styleName="foreground">
          <div styleName="input-inner">
            <input
              type={type}
              placeholder={placeholder}
              className="rbw-text"
              spellCheck="false"
              autoComplete="off"
              autoCapitalize="off"
              id={id}
              onKeyUp={this.onType}
              ref={(el) => { this.el = el; }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyUp: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  ref: () => {},
};

export default CSSModules(Input, styles);
