import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import cssModules from "react-css-modules";
import ScoreBoard from "Components/game/Scoreboard";
import styles from "Styles/pages/members.scss";
import api from "Actions/api";
import devlog from "Util/devlog";

class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.subscribeIfNeeded();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.roomName !== this.props.roomName) {
      this.subscribeIfNeeded();
    }
  }
  subscribeIfNeeded() {
    const roomName = this.props.roomName;
    console.log("subscribing...", roomName);

    if (roomName && roomName !== "general") {
      this.roomName = roomName;
      api.subscribeToRoomState(roomName, (newState) => {
        this.setState(newState);
      });
    }
  }
  componentWillUnmount() {
    api.unsubscribeFromRoomState(this.roomName);
  }

  render() {
    return (
      <div styleName="rulebook">
        <div style={{ height: "1rem" }} />
        <div>
          <h1>{this.roomName}</h1>
        </div>

        {this.state.host && (
          <ScoreBoard
            members={this.state.members}
            scores={this.state.scores}
            deltas={this.state.scores}
            host={this.state.host}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user, chat, game }) => ({
  name: user.name,
  roomName: user.room,
});
const mapDispatchToProps = {};
const withStyles = cssModules(Members, styles, { allowMultiple: true });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles);
