import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import App from './components/App';


const target = document.getElementById('root');

const renderMethod = target.hasChildNodes()
  ? ReactDOM.hydrate
  : ReactDOM.render;

const render = (Comp) => {
  renderMethod(
    <AppContainer>
      <Comp />
    </AppContainer>,
    target,
  );
};

// Render!
render(App);

// Hot Module Replacement
if (module && module.hot) {
  module.hot.accept('./components/App', () => {
    render(App);
  });
}
