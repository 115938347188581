import React from "react";
import { connect } from "react-redux";
import RoomView from "./RoomView";
import cssModules from "react-css-modules";
import { registerUser } from "Actions";
import InputButton from "Components/ui/InputButton";
import { setUserName } from "Actions";
import { withRouter } from "react-router";

import styles from "Styles/pages/room.scss";

class Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasName: false,
    };
    if (!props.match?.params?.id) {
      document.location = "/";
      return;
    }
    if (props.match.params.id === "general") {
      document.location = "/";
      return;
    }
  }
  componentDidMount() {
    if (this.props.user.name) {
      // document.location = '/';
      this.setState({
        hasName: true,
      });
      if (!this.props.initialized) {
        this.props.dispatch(
          registerUser(this.props.user, this.props.initialized),
        );
      }
    } else {
      this.props.location.state = {
        ...this.props.location.state,
        isUnnamed: true,
        destination: this.props.match?.params?.id,
      };
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.user.name !== prevProps.user.name) {
      this.props.location.state = {
        ...this.props.location.state,
        isUnnamed: false,
        destination: undefined,
      };
      this.setState({
        hasName: true,
      });
      if (!this.props.initialized) {
        this.props.dispatch(
          registerUser(this.props.user, this.props.initialized),
        );
      }
    }
  }
  render() {
    const { initialized, match } = this.props;
    const { hasName } = this.state;
    return (
      <div>
        {hasName ? (
          initialized ? (
            <RoomView match={match} />
          ) : (
            <div styleName="loading-page">
              <div styleName="loading-message">Loading...</div>
            </div>
          )
        ) : (
          <div styleName="enter-username-page">
            <div styleName="enter-username-form">
              <h1 className="rbw-text-xl">Really Boring Website</h1>
              <p className="rbw-text-l">
                Entering Room:{" "}
                <span styleName="underline-room">{match?.params?.id}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const withStyles = cssModules(Room, styles, { allowMultiple: true });

export default connect((state) => ({
  user: state.user,
  initialized: state.global.initialized,
}))(withStyles);
