// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".landing__landing__eS2BW {\n  overflow: hidden;\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background: #202020;\n  color: #f3f3f3; }\n\n.landing__main-outer__AzsXU {\n  margin: 1rem;\n  height: calc(100vh - (2 * 1rem));\n  border-bottom: none;\n  border-top: none; }\n  @media (min-width: 896px) {\n    .landing__main-outer__AzsXU {\n      margin: 2rem;\n      height: calc(100vh - (2 * 2rem)); } }\n\n.landing__main-full__22T9s {\n  height: 100%;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n\n.landing__logo__27vDB {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 9999; }\n\n.landing__button-wrap__9yF6S {\n  border: 2px solid #f3f3f3; }\n\n.landing__boring__MiS71 {\n  position: relative; }\n  @media (min-width: 896px) {\n    .landing__boring__MiS71 {\n      position: absolute;\n      left: calc(50vw - 1rem);\n      top: calc(50vh - 4rem); } }\n\n.landing__website__1_mqK {\n  position: relative; }\n  @media (min-width: 896px) {\n    .landing__website__1_mqK {\n      position: fixed;\n      left: 2rem;\n      bottom: 2rem; } }\n", ""]);
// Exports
exports.locals = {
	"landing": "landing__landing__eS2BW",
	"main-outer": "landing__main-outer__AzsXU",
	"main-full": "landing__main-full__22T9s",
	"logo": "landing__logo__27vDB",
	"button-wrap": "landing__button-wrap__9yF6S",
	"boring": "landing__boring__MiS71",
	"website": "landing__website__1_mqK"
};
module.exports = exports;
