import React from "react";
import { connect } from "react-redux";
import cssModules from "react-css-modules";
import { withRouter } from "react-router";

import { setUserName } from "Actions";
import Button from "Components/ui/Button";
import Rulebook from "Components/pages/Chat/Rulebook";
import Members from "Components/pages/Chat/Members";
import styles from "Styles/pages/chat-container.scss";
import Chat from "../Chat";

const MODES = {
  ROOM: "__room",
  LOBBY: "__lobby",
  LANDING: "__landing",
};
const TABS = {
  CHAT: "CHAT",
  ROOM: "ROOM",
  MEMBERS: "MEMBERS",
  RULES: "RULES",
};

const isValidUserName = (s = "") =>
  s && s.trim() && s.trim().length < 32 && /^[_a-zA-Z\d .]+$/g.test(s);
class ChatContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: MODES.LANDING,
      tab: TABS.CHAT,
      isOpen: true,
    };
    this.destroyed = false;
    this.selectUsername = this.selectUsername.bind(this);
    this.onRouteChanged = this.onRouteChanged.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }
  componentDidMount() {
    this.onRouteChanged();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  componentWillUnmount() {
    this.destroyed = true;
  }

  onRouteChanged() {
    const { pathname, state } = this.props.location;
    if (this.destroyed) {
      return;
    }
    if (state?.isUnnamed && state?.destination) {
      this.setState({
        mode: MODES.LANDING,
      });
    } else if (pathname.includes("/room/")) {
      this.setState({
        mode: MODES.ROOM,
      });
    } else if (pathname.includes("/lobby")) {
      this.setState({
        mode: MODES.LOBBY,
      });
    } else {
      this.setState({
        mode: MODES.LANDING,
      });
    }
  }
  changeTab(newTab) {
    if (this.state.tab !== newTab) {
      this.setState({
        tab: newTab,
      });
    }
  }

  selectUsername(name = "") {
    let destination = this.props.location?.state?.destination;
    // console.log('selectUsername, destination', destination);
    if (isValidUserName(name)) {
      this.props.dispatch(setUserName(name.trim(), destination));
      if (destination) {
        // this.props.history.push(`destination);
      } else {
        this.props.history.push("/lobby");
      }
    } else {
      alert("invalid username");
    }
  }
  render() {
    const isLanding = this.state.mode === MODES.LANDING;
    const { isTimerRunning } = this.props;
    const { tab } = this.state;
    return (
      <div
        styleName={`container ${this.state.mode} ${
          isTimerRunning ? "timer-on" : ""
        }`}
        className={this.state.mode}
      >
        <div styleName="tab-row">
          <button
            onClick={() => this.changeTab(TABS.CHAT)}
            styleName={`tab-button ${tab === TABS.CHAT ? "selected" : ""}`}
          >
            Chat
          </button>
          <button
            onClick={() => this.changeTab(TABS.MEMBERS)}
            styleName={`tab-button ${tab === TABS.MEMBERS ? "selected" : ""}`}
          >
            Room Members
          </button>
          <button
            onClick={() => this.changeTab(TABS.RULES)}
            styleName={`tab-button ${tab === TABS.RULES ? "selected" : ""}`}
          >
            Rulebook
          </button>
        </div>
        <div styleName={`tabbed-window ${tab}`}>
          <div styleName="chat-outer">
            <Chat
              overrideSendFn={isLanding ? this.selectUsername : null}
              displayMessages={!isLanding}
              isLanding={isLanding}
            />
          </div>
          <div styleName="members-outer">
            <Members />
          </div>
          <div styleName="rules-outer">
            <Rulebook />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(({ game }) => ({
    isTimerRunning: game.isTimerRunning,
  }))(cssModules(ChatContainer, styles, { allowMultiple: true })),
);
