import React from "react";
import { connect } from "react-redux";
import cssModules from "react-css-modules";
import InputButton from "Components/ui/InputButton";
import { withRouter } from "react-router";
import Game from "Components/game/Game";
import Types from "Actions/types";

import styles from "Styles/pages/room.scss";

class Spectate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasName: false,
    };
  }
  componentDidMount() {
    if (this.props.match.params.roomName) {
      this.props.dispatch({
        type: Types.ENTER_ROOM,
        roomName: this.props.match.params.roomName,
      });
    }
  }
  componentDidUpdate(prevProps) {}
  render() {
    const { match, roomName } = this.props;
    return <div> {roomName && roomName !== "general" && <Game />}</div>;
  }
}

const withStyles = cssModules(Spectate, styles, { allowMultiple: true });

export default connect(
  ({ user }) => ({ roomName: user.room }),
  (dispatch) => ({ dispatch }),
)(withStyles);
