import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import styles from 'Styles/animations.scss';


const FadeDown = ({ delay, children }) => (
  <div styleName="fade-down" className="animation--fade-down" style={{ animationDelay: `${delay}ms` }}>{children}</div>
);

FadeDown.propTypes = {
  delay: PropTypes.number,
};

export default cssModules(FadeDown, styles);
