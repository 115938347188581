// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pictionary__game-wrap-scrollable__Hmn3p {\n  position: relative;\n  padding: 4rem;\n  padding-top: 5rem;\n  overflow: scroll;\n  height: 100vh;\n  width: 100%; }\n\n.pictionary__canvas-wrap__1eUqV {\n  opacity: 1;\n  max-width: 620px;\n  margin: 0 auto; }\n  .pictionary__canvas-wrap__1eUqV .pictionary__canvas__10gfK {\n    border: 2px solid #e2e2e2;\n    border-radius: 5px;\n    background: #ffffff; }\n  .pictionary__canvas-wrap__1eUqV .pictionary__controls-above__37Y4B {\n    margin-bottom: 1rem;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between; }\n    .pictionary__canvas-wrap__1eUqV .pictionary__controls-above__37Y4B .pictionary__button-group__3kqUs {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: space-between; }\n      .pictionary__canvas-wrap__1eUqV .pictionary__controls-above__37Y4B .pictionary__button-group__3kqUs button {\n        margin-left: 0.5rem; }\n  .pictionary__canvas-wrap__1eUqV .pictionary__controls-under__32gO- {\n    margin-top: 1rem;\n    display: grid;\n    grid-template-columns: 1fr 1fr; }\n  .pictionary__canvas-wrap__1eUqV .react-colorful {\n    height: 100px; }\n  .pictionary__canvas-wrap__1eUqV .rc-slider {\n    padding: 2rem 0;\n    height: auto; }\n    .pictionary__canvas-wrap__1eUqV .rc-slider .rc-slider-rail {\n      background-color: #393939; }\n    .pictionary__canvas-wrap__1eUqV .rc-slider .rc-slider-track {\n      background-color: #e2e2e2; }\n    .pictionary__canvas-wrap__1eUqV .rc-slider .rc-slider-handle {\n      border: none;\n      height: 2rem;\n      width: 2rem;\n      margin-top: -1rem; }\n", ""]);
// Exports
exports.locals = {
	"game-wrap-scrollable": "pictionary__game-wrap-scrollable__Hmn3p",
	"canvas-wrap": "pictionary__canvas-wrap__1eUqV",
	"canvas": "pictionary__canvas__10gfK",
	"controls-above": "pictionary__controls-above__37Y4B",
	"button-group": "pictionary__button-group__3kqUs",
	"controls-under": "pictionary__controls-under__32gO-"
};
module.exports = exports;
