// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".system-button__system-button__20ovT.system-button__dangerous__2svg3 button {\n  background: #f93a26;\n  color: #f3f3f3; }\n  .system-button__system-button__20ovT.system-button__dangerous__2svg3 button:hover {\n    background: #ce0f0f;\n    color: #f3f3f3; }\n\n.system-button__system-button__20ovT button {\n  padding: 0.5rem 0.8rem;\n  border-radius: 0px;\n  transition: 150ms ease all;\n  border: 2px solid #f3f3f3;\n  border-radius: 4px; }\n  .system-button__system-button__20ovT button:hover {\n    background: #202020;\n    color: #f3f3f3; }\n  .system-button__system-button__20ovT button:active {\n    transform: scale(0.98); }\n\n.system-button__system-button__20ovT .system-button__text__35udD {\n  font-family: 'rbw-sans';\n  font-size: 1rem;\n  font-weight: bold; }\n", ""]);
// Exports
exports.locals = {
	"system-button": "system-button__system-button__20ovT",
	"dangerous": "system-button__dangerous__2svg3",
	"text": "system-button__text__35udD"
};
module.exports = exports;
