import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import styles from 'Styles/animations.scss';


const FadeUp = ({ delay, style, reverse = false, children }) => (
  <div
    styleName="fade-up"
    className="animation--fade-up"
    style={{ animationDelay: `${delay}ms`, ...style, animationDirection: reverse ? 'reverse' : 'normal' }}
  >
    {children}
  </div>
);

FadeUp.propTypes = {
  key: PropTypes.string,
  delay: PropTypes.number,
  style: PropTypes.object, //eslint-disable-line
};

export default cssModules(FadeUp, styles);
