
import Types from '../actions/types';

const CHAT_CACHE_MAX = 5;
const addToChatCache = (c = [], m) => {
  if (!m) {
    return c;
  }
  if (c.length > CHAT_CACHE_MAX) {
    c.pop();
  }
  c.unshift(m);
  return c;
};

export default function (state = { cursor: 0, chatCache: [] }, action) {
  switch (action.type) {
    case Types.ADD_CHAT_CACHE:
      return {
        ...state,
        chatCache: addToChatCache(state.chatCache, action.message),
        cursor: 0,
      };
    case Types.SET_CHAT_CACHE_CURSOR:
      return {
        ...state,
        cursor: action.cursor,
      };
    case Types.CLEAR_CHAT_CACHE:
      return {
        ...state,
        cursor: 0,
        chatCache: [],
      };
    default:
      return state;
  }
}
