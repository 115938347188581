import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import styles from 'Styles/animations.scss';


const FadeRight = ({ on = true, delay, style, leave = false, children }) => (
  on ? (
    <div
      styleName={`fade-right-${leave ? 'out' : 'in'}`}
      className="animation--fade-right"
      style={{ ...style, animationDelay: `${delay}ms` }}
    >
      {children}
    </div>
  ) : (
    <div style={style}>
      {children}
    </div>
  )
);

FadeRight.propTypes = {
  on: PropTypes.bool,
  delay: PropTypes.number,
  leave: PropTypes.bool,
  style: PropTypes.object,
};


export default cssModules(FadeRight, styles);
