import React from "react";
import api from "Actions/api";
import cssModules from "react-css-modules";

import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import throttle from "lodash/throttle";
import maxBy from "lodash/maxBy";
import { connect } from "react-redux";

import styles from "Styles/game/scoreboard.scss";

class ScoreBoard extends React.Component {
  constructor(props) {
    super(props);

    this.throttledNewGame = throttle(api.nextGamePhase, 1000);
    this.throttledNewGame = this.throttledNewGame.bind(this);
  }

  makeHost(id) {
    api.makeHost(id);
  }
  render() {
    // console.log(324, this.props.lastScores, this.props.scores);
    const { members, scores, host, myId, lastScores } = this.props;
    const membersArr = Object.values(members).map((member) => {
      let isHost = member.id === host;

      if (member.id in scores) {
        let delta = 0;
        if (member.id in lastScores) {
          delta = scores[member.id] - lastScores[member.id];
        }
        return {
          ...member,
          isHost,
          score: scores[member.id],
          delta,
        };
      }
      return {
        ...member,
        isHost,
        score: 0,
        delta: 0,
      };
    });
    const scoreSheet = reverse(
      sortBy(membersArr, ["score", "delta", "username"]),
    );
    const maximumRoundScore = maxBy(membersArr, "delta");
    const hasResults = scores && Object.keys(scores).length;
    const iAmHost = host === myId;
    // const hasDeltas =
    //   results &&
    //   results.roundScores &&
    //   Object.values(results.roundScores).some((n) => !!n);
    //   console.log(results);
    return (
      <div styleName="scoreboard-wrap">
        <div styleName="title">
          {hasResults ? "Results" : "Players in Room"}
        </div>
        {scoreSheet.map((member, index) => (
          <div styleName="scoreboard-item" key={member.id}>
            <div styleName="scoreboard-username">
              {!!hasResults && !!(index === 0) && (
                <span styleName="scoreboard-trophy">👑</span>
              )}
              {!!hasResults &&
                !!(member.delta === maximumRoundScore?.delta) &&
                !!member.delta && (
                  <span styleName="scoreboard-trophy">📈</span>
                )}{" "}
              {member.name}
              {member.isHost ? (
                <span styleName="scoreboard-host">{"(host)"}</span>
              ) : iAmHost ? (
                <button
                  styleName="make-host-button"
                  onClick={() => this.makeHost(member.id)}
                >
                  Make host
                </button>
              ) : (
                <div />
              )}
            </div>
            <div styleName="scoreboard-score">{member.score}</div>
            {!!member.delta && (
              <div styleName="scoreboard-score-delta">+{member.delta}</div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
ScoreBoard.defaultProps = {
  scores: {},
  lastScores: {},
};
const mapStateToProps = ({ user }) => ({
  myId: user.id,
});
export default connect(mapStateToProps)(
  cssModules(ScoreBoard, styles, { allowMultiple: true }),
);
