import React from 'react';
import PropTypes from 'prop-types';
import api from 'Actions/api';
import { connect } from 'react-redux';

import { startTimer, stopTimer, timerTick, setUserData } from 'Actions';
import CategoriesView from './CategoriesView';

class Categories extends React.Component {
  componentWillMount() {
    this.props.startTimer();
    console.log('attempJoinGame...');
    api.joinGame().then((result) => {
      console.log('result', result);
      if (result.joined) {
        console.log('joined...');
        this.props.setUserData({
          in_game: true,
          gameId: result.id,
        });
      } else {
        alert('The game is full, you did not join this round.');
      }
    });
    api.subscribeToTimer((timeLeft) => {
      this.props.timerTick(timeLeft);
    });
  }
  componentWillUnmount() {
    api.unsubscribeFromTimer();
    this.props.stopTimer();
  }
  render() {
    const { words, letter, gameId } = this.props;
    return (
      <CategoriesView words={words} letter={letter} gameId={gameId} />
    );
  }
}
Categories.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string),
  startTimer: PropTypes.func,
  stopTimer: PropTypes.func,
  timerTick: PropTypes.func,
  letter: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  roomName: user.room,
  gameId: user.gameId,
});
const mapDispatchToProps = {
  startTimer,
  stopTimer,
  timerTick,
  setUserData,
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
