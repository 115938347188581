import React, { Component } from 'react';
import cssModules from 'react-css-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styles from 'Styles/pages/landing.scss';

class Landing extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { user, history } = this.props;
    if (user.name) {
      if (user.room && user.room !== 'general') {
        history.push(`/room/${user.room}`);
      } else {
        history.push(`/lobby`);
      }
    }
  }
  render() {
    return (
      <div styleName="landing">
        <div styleName="main-outer">
          <div styleName="main-full">
            <div styleName="logo" className="rbw-text-l">
              Really<br />
              <div styleName="boring">Boring</div>
              <div styleName="website">Website</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const withStyles = cssModules(Landing, styles, { allowMultiple: true });
const connectedToRedux = connect(state => ({
  user: state.user,
  initialized: state.global.initialized,
}))(withStyles);
export default withRouter(connectedToRedux);
