import { connect } from 'react-redux';
import TimerView from './TimerView';
import PropTypes from 'prop-types';

const leadZero = n => `0${n}`.slice(-2);
const mapStateToProps = ({ game }) => {
  const timeLeft = Math.max(0, game.timeLeft || 0);
  const minutes = `${Math.floor(timeLeft / 60)}`;
  const seconds = leadZero(timeLeft % 60);
  return {
    minutes,
    seconds,
  };
};
export default connect(mapStateToProps)(TimerView);
