import React from "react";
import cssModules from "react-css-modules";
import PropTypes from "prop-types";
import Button from "Components/ui/Button";
import styles from "Styles/ui/system-button.scss";

class SystemButton extends React.Component {
  render() {
    const { children, onClick, type, dangerous } = this.props;
    return (
      <div styleName={`system-button ${dangerous ? "dangerous" : ""}`}>
        <Button type={type} onClick={onClick}>
          <div styleName="text">{children}</div>
        </Button>
      </div>
    );
  }
}

SystemButton.propTypes = {
  onClick: PropTypes.func,
};
SystemButton.defaultProps = {
  onClick: () => {},
  dangerous: false,
};

export default cssModules(SystemButton, styles, { allowMultiple: true });
