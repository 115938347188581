import React, { Component } from "react";
import PropTypes from "prop-types";
import SystemButton from "Components/ui/SystemButton";

class ConfirmButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedOnce: false,
    };
    this.timeout = -1;
  }
  handleClick = (e) => {
    clearTimeout(this.timeout);
    if (this.state.clickedOnce) {
      this.props.onClick(e);
      this.setState({
        clickedOnce: false,
      });
    } else {
      this.setState({
        clickedOnce: true,
      });
      this.timeout = setTimeout(() => {
        this.setState({
          clickedOnce: false,
        });
      }, 5000);
    }
  }
  render() {
    const { children } = this.props;
    const { clickedOnce } = this.state;
    return (
      <div>
        <SystemButton onClick={this.handleClick} dangerous={clickedOnce}>{clickedOnce ? "Are you sure?" : children}</SystemButton>
      </div>
    );
  }
}

ConfirmButton.propTypes = {};

export default ConfirmButton;
