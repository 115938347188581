import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import SvgIcon from 'Components/utility/SvgIcon';
import styles from 'Styles/game/timer.scss';

const TimerView = ({ minutes, seconds }) => (
  <div styleName="timer-wrap">
    <div styleName="icon-wrap"><SvgIcon glyph="stopwatch" /></div>
    <div styleName="minutes">{minutes}</div><span>:</span><div styleName="seconds">{seconds}</div>
  </div>
);

TimerView.propTypes = {
  minutes: PropTypes.string,
  seconds: PropTypes.string,
};

TimerView.defaultProps = {
  minutes: '00',
  seconds: '00',
};

export default cssModules(TimerView, styles);
