import React from 'react';

const H = ({ em = 1, styleName = '', styleBoth = {}, children }) => (
  <div className="_l" style={{ ...styleBoth }}>
    <div style={{ fontSize: `${em}em`, ...styleBoth }} className={styleName}>
      {children}
    </div>
  </div>
);
const M = ({ em = 1, styleName = '', styleBoth = {}, children }) => (
  <div className="_m" style={{ ...styleBoth }}>
    <div style={{ fontSize: `${em}em`, ...styleBoth }} className={styleName}>
      {children}
    </div>
  </div>
);
const S = ({ em = 1, styleName = '', styleBoth = {}, children }) => (
  <div className="_s" style={{ ...styleBoth }}>
    <div style={{ fontSize: `${em}em`, ...styleBoth }} className={styleName}>
      {children}
    </div>
  </div>
);

export default { H, M, S };
