import React from 'react';
import cssModules from 'react-css-modules';
import PropTypes from 'prop-types';
import styles from 'Styles/animations.scss';


const SlideUp = ({ on = true, delay, style, children }) => (
  on ? (
    <div
      styleName="slide-up-out"
      className="animation--fade-away"
      style={{ ...style, animationDelay: `${delay}ms` }}
    >
      {children}
    </div>
  ) : (
    <div>
      {children}
    </div>
  )
);

SlideUp.propTypes = {
  on: PropTypes.bool,
  delay: PropTypes.number,
  style: PropTypes.object,
};


export default cssModules(SlideUp, styles);
