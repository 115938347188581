// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button__button__26I0K {\n  font-size: 1em;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  padding: 0 2rem;\n  transition: 200ms linear background, 100ms linear transform;\n  font-family: 'rbw-text';\n  border: 1px solid #909090; }\n  .button__button__26I0K:hover {\n    background: #f2f2f2; }\n  .button__button__26I0K:active {\n    transform: scale(0.98); }\n  .button__button__26I0K .button__default__1e8Wl {\n    color: #e2e2e2;\n    background: #fff; }\n", ""]);
// Exports
exports.locals = {
	"button": "button__button__26I0K",
	"default": "button__default__1e8Wl"
};
module.exports = exports;
