import React from "react";
import { connect } from "react-redux";
import cssModules from "react-css-modules";
import styles from "Styles/pages/room.scss";

import Game from "Components/game/Game";
import Input from "Components/ui/Input";
import { FadeAway, FadeRight } from "Components/animations";
import Chat from "Components/pages/Chat";
import api from "Actions/api";
import { setUserRoom } from "Actions";

import Axis from "Components/utility/Axis";
import emitter from "Util/emitter";

import devlog from "Util/devlog";
import RoomControls from "./RoomControls";

class RoomView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      members: {},
      chatOpen: true,
      initialized: false,
    };
    this.toggleChat = this.toggleChat.bind(this);
  }
  componentWillMount() {
    if (!this.props.user.name) {
      document.location = "/";
      return;
    }
    if (!this.props.match?.params?.id) {
      document.location = "/";
      return;
    }
  }
  componentDidMount() {
    const roomName = this.props.match?.params?.id;
    this.roomName = roomName;
    this.props.dispatch(setUserRoom(roomName));
    api.subscribeToRoomState(roomName, (newState) => {
      devlog("new Room State", newState);
      if (!this.state.initialized) {
        this.setState({
          initialized: true,
        });
      }
      this.setState(newState);
    });
    emitter.emit("alert", {
      message: "Joined Room",
      variable: roomName,
      type: "info",
    });
  }
  componentWillUnmount() {
    api.unsubscribeFromRoomState(this.roomName);
  }
  toggleChat() {
    this.setState({
      chatOpen: !this.state.chatOpen,
    });
  }
  render() {
    const { chatOpen, initialized } = this.state;
    const roomName = this.props.match?.params?.id;
    const { isTimerRunning } = this.props;
    let audienceNumber = 0;
    let playerNumber = 0;
    for (let member in this.state.members) {
      if (this.state.members[member].in_game) {
        playerNumber += 1;
      } else {
        audienceNumber += 1;
      }
    }

    return (
      <div
        styleName={`room-wrap ${isTimerRunning ? "timer-on" : ""} ${
          chatOpen ? "" : "chat-closed"
        }`}
      >
        <RoomControls
          name={roomName}
          audienceNumber={audienceNumber}
          playerNumber={playerNumber}
        />
        <div styleName="frame">
          <div styleName="divider" />
          <div styleName="frame-section game-frame">
            <>
              {initialized ? (
                <Game {...this.state} roomName={roomName} />
              ) : (
                <div>joining room...</div>
              )}
            </>
          </div>
        </div>
        <div className="rbw-logo-bottom rbw-text">
          <div>
            <div>Really.Boring.Website</div>
          </div>
          <div>© {new Date().getFullYear()}</div>
        </div>
      </div>
    );
  }
}

const withStyles = cssModules(RoomView, styles, { allowMultiple: true });
export default connect(({ user, game }) => ({
  user,

  isTimerRunning: game.isTimerRunning,
}))(withStyles);
