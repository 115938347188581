import React from "react";
import cssModules from "react-css-modules";

import Categories from "Components/game/Categories";
import WaitingRoom from "Components/game/WaitingRoom";
import Voting from "Components/game/Voting";
import Timer from "Components/game/Timer";
import LetterView from "Components/game/Categories/LetterView";
import AnswerCollector from "Components/game/AnswerCollector";
import VotingRounds from "Components/game/Voting/VotingRounds";

import styles from "Styles/game/game-view.scss";

import config from "../gameConfig";

const GameView = (props) => {
  const {
    phase,
    letter,
    words,
    round,
    answers,
    scores,
    lastScores,
    members,
    players,
    uid,
    host,
    isPrivate,
    isDefault,
    gameTime,
    isAnonymous,
  } = props;
  const isPlaying = players && uid in players;
  // console.log("isPlaying", players, uid);
  if (phase === config.PHASES.ANSWERING) {
    return (
      <div styleName="game-wrap">
        <div styleName="top-section">
          <Timer />

          <LetterView letter={letter} />
        </div>
        <div styleName="bottom-section">
          <div styleName="playing-info">
            <span>
              Answer each category with a word or phrase starting with the
              letter&nbsp;{letter}
            </span>
          </div>
          <Categories words={words} letter={letter} />
        </div>
      </div>
    );
  } else if (phase === config.PHASES.WAIT_FOR_ANSWERS) {
    return <AnswerCollector players={players} />;
  } else if (phase === config.PHASES.VOTING) {
    return (
      <div styleName="game-wrap">
        <div styleName="top-section">
          <VotingRounds
            round={round}
            words={words}
            isPlaying={isPlaying}
            host={host}
          />
        </div>
        <div styleName="bottom-section">
          {!isPlaying && (
            <div styleName="voting-alert-not-playing">
              You joined this game too late. You will not be able to vote until
              next round.
            </div>
          )}
          <Voting
            answers={answers}
            canVote={isPlaying}
            round={round}
            players={players}
            isAnonymous={isAnonymous}
          />
        </div>
      </div>
    );
  }

  if (phase === config.PHASES.WAITING) {
    return (
      <WaitingRoom
        scores={scores}
        lastScores={lastScores}
        members={members}
        host={host}
        isPrivate={isPrivate}
        isDefault={isDefault}
        gameTime={gameTime}
        isAnonymous={isAnonymous}
      />
    );
  }
  return <div>loading...</div>;
};

GameView.defaultProps = {
  players: {},
};

export default cssModules(GameView, styles);
