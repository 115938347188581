import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import Types from 'Actions/types';
import styles from 'Styles/ui/input-field.scss';

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.props.dispatch({
      type: Types.CHANGE_INPUT,
      name: this.props.name,
      value: e.target.value,
      namespace: this.props.namespace,
    });
  }
  render() {
    return (
      <input
        placeholder={this.props.placeholder}
        spellCheck="false"
        autoComplete="false"
        autoCorrect="off"
        autoCapitalize="false"
        onKeyUp={this.props.onKeyUp}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        id={this.props.id}
        name={this.props.name}
        onChange={this.handleChange}
        value={this.props.value}
        styleName="input"
      />
    );
  }
}

InputField.propTypes = {
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  namespace: PropTypes.string,
  placeholder: PropTypes.string,
};
InputField.defaultProps = {
  value: '',
  namespace: '',
  placeholder: '',
};

const mapStateToProps = (state, otherProps) => {
  if (state.user.gameId && state.answers[state.user.gameId]) {
    return {
      value: state.answers[state.user.gameId][otherProps.name],
      namespace: state.user.gameId,
    };
  }
  return {
    value: '',
    namespace: state.user.gameId || '',
  };
};
export default connect(mapStateToProps)(CSSModules(InputField, styles));
