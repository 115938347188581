// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".rulebook__rulebook__339Zu {\n  border: 2px solid #f3f3f3;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  top: 0;\n  background: #202020;\n  padding: 1rem;\n  overflow: scroll;\n  padding-bottom: 4rem; }\n  .rulebook__rulebook__339Zu::-webkit-scrollbar {\n    display: none; }\n  .rulebook__rulebook__339Zu h1 {\n    font-size: 4rem;\n    margin: 0;\n    margin-bottom: 1rem; }\n  .rulebook__rulebook__339Zu .rulebook__bump-left__3fzdE {\n    margin-left: -1rem; }\n  .rulebook__rulebook__339Zu .rulebook__important__3cKKA {\n    background: rgba(243, 243, 243, 0.9);\n    color: #202020;\n    padding: 1rem; }\n  .rulebook__rulebook__339Zu a {\n    color: #f3f3f3; }\n  .rulebook__rulebook__339Zu .rulebook__p__3qAmk {\n    margin: 1em 0; }\n", ""]);
// Exports
exports.locals = {
	"rulebook": "rulebook__rulebook__339Zu",
	"bump-left": "rulebook__bump-left__3fzdE",
	"important": "rulebook__important__3cKKA",
	"p": "rulebook__p__3qAmk"
};
module.exports = exports;
