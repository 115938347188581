const { EVENTS } = globalConfig;

export default (socket) => ({
  sendLine(line) {
    socket.emit(EVENTS.PICTIONARY.addLine, line);
  },
  sendUndo(room) {
    socket.emit(EVENTS.PICTIONARY.requestUndo, { room });
  },
  sendReset(room) {
    socket.emit(EVENTS.PICTIONARY.requestReset, { room });
  },
  getWord(room, cb) {
    socket.emit(EVENTS.PICTIONARY.getWord, { room }, cb);
  },
  subscribeToBoardActions(room, handleNewLine, handleUndo, handleReset, handleAllLines) {
    socket.on(EVENTS.PICTIONARY.newLine, handleNewLine);
    socket.on(EVENTS.PICTIONARY.undo, handleUndo);
    socket.on(EVENTS.PICTIONARY.reset, handleReset);
    socket.emit(EVENTS.PICTIONARY.getAllLines, { room }, handleAllLines);

    return () => {
      socket.off(EVENTS.PICTIONARY.newLine);
      socket.off(EVENTS.PICTIONARY.undo);
      socket.off(EVENTS.PICTIONARY.reset);
    };
  },
});
