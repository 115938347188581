import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import localForage from 'localforage';

import History from 'Util/History';
import UserService from 'Util/UserService';

import { setUserId, setUserRoom } from 'Actions';
import reducers from './reducers';
import devlog from 'Util/devlog';

const config = {
  key: 'rbw',
  storage: localForage,
  blacklist: ['global'],
};

const reducer = persistCombineReducers(config, reducers);
const browserHistory = History;

export default function configureStore(initialState) {
  //eslint-disable-next-line
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, initialState, composeEnhancers(
    applyMiddleware(
      routerMiddleware(browserHistory),
      thunk,
    ),
  ));


  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  const afterRehydrate = () => {
    const state = store.getState();
    devlog('afterRehydrate', state);

    const id = UserService.getId(state.user);
    store.dispatch(setUserId(id)); // will register and route user

  };
  const persistor = persistStore(
    store,
    {},
    afterRehydrate,
  );

  return {
    store,
    persistor,
  };
}
