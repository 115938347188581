import Types from 'Actions/types';

import { routerReducer } from 'react-router-redux';

import gameReducer from './gameReducer';
import userReducer from './userReducer';
import answersReducer from './answersReducer';
import chatReducer from './chatReducer';

import devlog from 'Util/devlog';

function globalReducer(state = {}, action) {
  switch (action.type) {
    case Types.USER_LOADED:
      devlog('Initialized', true);
      return {
        ...state,
        initialized: true,
      };
    default:
      return state;
  }
}

export default {
  game: gameReducer,
  user: userReducer,
  chat: chatReducer,
  answers: answersReducer,
  routing: routerReducer,
  global: globalReducer,
};
