// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".game-view__top-section__1HkeG {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  height: 8rem;\n  border-bottom: 2px solid #f3f3f3;\n  z-index: 11;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  box-sizing: border-box;\n  background: #202020; }\n\n.game-view__bottom-section__L9PBF {\n  position: absolute;\n  top: 8rem;\n  right: 0;\n  left: 0;\n  padding-bottom: 8rem;\n  overflow: visible; }\n  @media (min-width: 896px) {\n    .game-view__bottom-section__L9PBF {\n      bottom: 0;\n      padding-bottom: 0;\n      position: absolute;\n      overflow-y: scroll; }\n      .game-view__bottom-section__L9PBF::-webkit-scrollbar {\n        display: none; } }\n\n.game-view__game-wrap__FvUJC {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  border: 2px solid;\n  margin: 0;\n  border-bottom: none; }\n  @media (min-width: 896px) {\n    .game-view__game-wrap__FvUJC {\n      margin: 0 2rem;\n      margin-right: 0;\n      border-right: none;\n      border-bottom: 2px solid; } }\n\n.game-view__playing-info__3FSbk {\n  color: #fff;\n  padding: 1rem 2rem;\n  font-size: 1.2rem;\n  flex: 1 1 auto;\n  border-bottom: 2px solid #e2e2e2; }\n  .game-view__playing-info__3FSbk span {\n    opacity: 0.5; }\n\n.game-view__voting-alert-not-playing__1spFb {\n  color: #fff;\n  padding: 1rem;\n  background: rgba(234, 125, 42, 0.5);\n  border-bottom: 2px solid #ea7d2a; }\n", ""]);
// Exports
exports.locals = {
	"top-section": "game-view__top-section__1HkeG",
	"bottom-section": "game-view__bottom-section__L9PBF",
	"game-wrap": "game-view__game-wrap__FvUJC",
	"playing-info": "game-view__playing-info__3FSbk",
	"voting-alert-not-playing": "game-view__voting-alert-not-playing__1spFb"
};
module.exports = exports;
