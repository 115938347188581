import React from "react";
import CSSModules from "react-css-modules";

import styles from "Styles/ui/toggle.scss";

const Toggle = ({ isOn, onClick, onText = "On", offText = "Off" }) => {
  return (
    <div styleName={`toggle-wrap ${isOn ? "on" : "off"}`}>
      <button styleName="toggle" onClick={onClick}>
        <div styleName="toggle-inner" className="rbw-text">
          <div className="rbw-text">{offText}</div>

          <div styleName="toggle-track">
            <div styleName="toggle-circle"></div>
          </div>
          <div className="rbw-text">{onText}</div>
        </div>
      </button>
    </div>
  );
};

export default CSSModules(Toggle, styles, { allowMultiple: true });
