import React from "react";
import cssModules from "react-css-modules";
import styles from "Styles/pages/chat.scss";

import InputButton from "Components/ui/InputButton";
import ChatMessage from "./ChatMessage";

const ChatView = ({
  messages = [],
  onSend,
  onChatKeyUp,
  displayMessages,
  isLanding,
  isTimerRunning,
  myName,
}) => (
  <div
    styleName={`chat-wrap ${displayMessages ? "" : "hide-messages"} ${
      isTimerRunning ? "timer-running" : ""
    }`}
  >
    <div styleName="messages-wrap" id="chat-messages-wrap">
      {!!messages.length && (
        <div styleName="messages" className="1">
          {messages.map((message) => {
            if (message.type) {
              return (
                <div
                  key={message.time + message.offsetTime}
                  styleName={`message message-${message.type}`}
                >
                  {message.message}{" "}
                  {message.variable && (
                    <span>
                      ⟶{" "}
                      <div styleName="message-variable">{message.variable}</div>
                    </span>
                  )}{" "}
                  {message.link && (
                    <span>
                      ⟶{" "}
                      <a
                        styleName="message-variable"
                        href={message.link}
                        target="_blank"
                      >
                        {message.linkText}
                      </a>
                    </span>
                  )}
                  {message.subtext && (
                    <span styleName="message-subtext">{`${message.subtext}`}</span>
                  )}
                </div>
              );
            }
            return (
              <ChatMessage {...message} key={message.time} myName={myName} />
            );
          })}
        </div>
      )}
      {!messages.length && (
        <div styleName="empty-wrap" className="noselect">
          <div styleName="empty" className="rbw-text">
            No Messages
          </div>
        </div>
      )}
    </div>
    <div styleName="bottom-wrap">
      <form action="#" onSubmit={onSend}>
        <InputButton
          inputProps={{
            id: "chat-message-input",
            onKeyUp: onChatKeyUp,
            placeholder: isLanding ? "Username" : "Send a message",
          }}
          buttonProps={{ onClick: onSend }}
          buttonText={isLanding ? "Enter" : "Send"}
        />
      </form>
    </div>
  </div>
);

export default cssModules(ChatView, styles, { allowMultiple: true });
