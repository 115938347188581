import React from "react";
import api from "Actions/api";
import cssModules from "react-css-modules";
import { Formik } from "formik";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import throttle from "lodash/throttle";
import maxBy from "lodash/maxBy";
import { connect } from "react-redux";
import ScoreBoard from "Components/game/ScoreBoard";

import SystemButton from "Components/ui/SystemButton";
import Button from "Components/ui/Button";
import Input from "Components/ui/Input";
import Toggle from "Components/ui/Toggle";
import InputButton from "Components/ui/InputButton";
import ConfirmButton from "Components/ui/ConfirmButton";

import styles from "Styles/game/waiting-room.scss";

class WaitingRoom extends React.Component {
  constructor(props) {
    super(props);
    this.throttledNewGame = throttle(api.nextGamePhase, 1000);
    this.throttledNewGame = this.throttledNewGame.bind(this);
  }
  resetScores() {
    api.resetScores();
  }
  makeHost(id) {
    api.makeHost(id);
  }
  toggleRoomPrivacy = () => {
    if (this.props.isPrivate) {
      api.setPrivacyLevel("PUBLIC");
    } else {
      api.setPrivacyLevel("PRIVATE");
    }
  };
  toggleRoomAnonymity = () => {
    api.setAnonymity(!this.props.isAnonymous, () => {
      console.log("updated");
    });
  };
  updateTime = (formData, formControl) => {
    return new Promise((resolve, reject) => {
      api.updateTime(formData.time, (updatedTime) => {
        console.log("updated", updatedTime);
        formControl.setValues({
          time: updatedTime,
        });
        resolve();
      });
    });
  };
  setList = async (formData, formControl) => {
    console.log("submit", formData);
    await api.updateList(formData.listHash);
    formControl.resetForm();
  };
  render() {
    const {
      members,
      scores,
      lastScores,
      host,
      myId,
      isPrivate,
      isDefault,
      gameTime,
      isAnonymous,
    } = this.props;

    const iAmHost = host === myId;
    return (
      <div styleName="waiting-room-wrap">
        {iAmHost && (
          <div styleName="host-controls">
            <div className="rbw-text-l">Host Controls</div>
            <div style={{ marginBottom: "1rem" }} />
            {!isDefault && (
              <div>
                <div styleName="host-control-line">
                  <div>Time (seconds)</div>
                  <div>
                    <Formik
                      onSubmit={this.updateTime}
                      initialValues={{ time: gameTime }}
                    >
                      {(props) => (
                        <form onSubmit={props.handleSubmit}>
                          <input
                            ref={(el) => {
                              this.timerInputEl = el;
                            }}
                            name="time"
                            value={props.values.time}
                            type="number"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <div className={styles["time-input-bottom"]}>
                            <div className="rbw-text 1">
                              {" "}
                              {Math.round(100 * (props.values.time / 60)) /
                                100}{" "}
                              minute{props.values.time / 60 === 1 ? "" : "s"}
                            </div>
                            <SystemButton type="submit">Update</SystemButton>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>

                {/* <div style={{ marginBottom: "2rem" }} /> */}
                <div styleName="host-control-line">
                  <div>Show room in lobby</div>
                  <Toggle
                    onClick={this.toggleRoomPrivacy}
                    isOn={!isPrivate}
                    onText="Public"
                    offText="Private"
                  />
                </div>
                <div styleName="host-control-line">
                  <div>Show usernames next to answers</div>
                  <Toggle
                    onClick={this.toggleRoomAnonymity}
                    isOn={!isAnonymous}
                    onText="Yes"
                    offText="No"
                  />
                </div>
                {/* 
                <div styleName="host-control-line">
                  <div>Extra points for alliterations</div>
                  <Toggle />
                </div> */}
              </div>
            )}
            <div styleName="host-control-line">
              <div>Reset all scores to 0</div>
              <ConfirmButton onClick={this.resetScores}>
                Reset Scores
              </ConfirmButton>
            </div>
            {!isDefault && (
              <div styleName="host-control-line">
                <div>
                  Use a custom list
                  <div styleName="host-control-info">
                    Create a custom list{" "}
                    <a
                      href="https://swellgarfo.com/scattergories"
                      target="_blank"
                    >
                      here
                    </a>{" "}
                    and then paste the url.
                  </div>
                </div>
                <Formik
                  onSubmit={this.setList}
                  initialValues={{ listHash: "" }}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <input
                        name="listHash"
                        value={props.values.listHash}
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      <div className={styles["time-input-bottom"]}>
                        <SystemButton type="submit">Find List</SystemButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
            <div styleName="host-control-line">
              <div>Play Pictionary ✏️</div>
              <SystemButton onClick={api.changeGameMode}>Go</SystemButton>
            </div>
            <div style={{ marginBottom: "2rem" }} />

            <SystemButton onClick={this.throttledNewGame}>
              <div className={`${styles["cta"]} rbw-text-l`}>New Game ⏱</div>
            </SystemButton>
          </div>
        )}
        <ScoreBoard
          members={members}
          scores={scores}
          lastScores={lastScores}
          host={host}
        />
      </div>
    );
  }
}
WaitingRoom.defaultProps = {
  scores: {},
  lastScores: {},
};
const mapStateToProps = ({ user }) => ({
  myId: user.id,
});
export default connect(mapStateToProps)(
  cssModules(WaitingRoom, styles, { allowMultiple: true }),
);
